import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { GlobalStorageKey } from "../models/global-storage/global-storage-keys";
import { GlobalStorageOutDTO } from "../models/global-storage/global-storage-out.dto";

export class GlobalStorageService {
  constructor(private methods: HttpMethod) {}

  @memoize(86400000, "GlobalStorageService")
  async get<T>(key: GlobalStorageKey) {
    return await this.methods.get<T>(`/global-storage/${key}`);
  }

  async set(key: GlobalStorageKey, value: number | string) {
    clearBucketMemoization("GlobalStorageService");
    const result = await this.methods.patch(`/global-storage/${key}`, {
      value,
    });
    return plainToInstance(GlobalStorageOutDTO, result);
  }

  async delete(key: GlobalStorageKey) {
    clearBucketMemoization("GlobalStorageService");
    await this.methods.delete(`/global-storage/${key}`);
  }

  @memoize(86400000, "GlobalStorageService")
  async getAll() {
    const result = await this.methods.get(`/global-storage`);
    return plainToInstance(GlobalStorageOutDTO, result);
  }
}
