import { HttpMethod } from "../http-method.interface";
import { EnterpriseOutDTO } from "../models/enterprise/enterprise.out.dto";
import { PersonInEnterpriseOutDTO } from "../models/enterprise/person-in-enterprise-out.dto";
import { PersonFieldsDataWithRole } from "../models/person/form/person-fields-data-with-role";
import { ShopFieldsData } from "../models/shop/form/shop-fields-data";
import { PersonInShopOutDTO } from "../models/shop/person-in-shop-out.dto";
import { ShopOutDTO } from "../models/shop/shop.out.dto";
import { EnterpriseService } from "../services/enterprise.service";
import { ShopService } from "../services/shop.service";
import { PersonUtilService } from "./person-util.service";

export class ShopUtilService {
  constructor(private methods: HttpMethod) {}

  async upsertShopWithEnterpriseAndPersons(
    shop: ShopFieldsData,
    linkedShopIds: string[],
    activeEnterprise: EnterpriseOutDTO | null,
    personsToCreateInShop: PersonFieldsDataWithRole[] = [],
    personsToLinkInShop: PersonInShopOutDTO[] = [],
    personsToUpdateInShop: PersonInShopOutDTO[] = [],
    personsToCreateInEnterprise: PersonFieldsDataWithRole[] = [],
    personsToLinkInEnterprise: PersonInEnterpriseOutDTO[] = [],
    personsToUpdateInEnterprise: PersonInEnterpriseOutDTO[] = []
  ) {
    const shopService = new ShopService(this.methods);
    const enterpriseService = new EnterpriseService(this.methods);
    const personUtilService = new PersonUtilService(this.methods);

    let updatedShop: ShopOutDTO;
    // Create new Shop
    if (!shop.id) {
      updatedShop = await shopService.create(
        shop.pointOfSaleName,
        shop.establishmentName,
        shop.lang,
        shop.establishmentNumber || undefined,
        shop.active,
        shop.phones,
        shop.emails,
        shop.coordinates,
        shop.address1 || undefined,
        shop.address2 || undefined,
        Number.parseInt(shop.postalCode),
        shop.city,
        shop.houseNumber || undefined,
        shop.openingHours,
        linkedShopIds,
        activeEnterprise?.cbeNumber,
        shop.separateRoom,
        shop.salesmanId
      );
    }
    // Update Shop
    else {
      updatedShop = await shopService.update(
        shop.id,
        shop.pointOfSaleName || undefined,
        shop.establishmentName || undefined,
        shop.lang || undefined,
        shop.establishmentNumber || null,
        shop.active,
        shop.phones || undefined,
        shop.emails || undefined,
        shop.coordinates || undefined,
        shop.address1 || null,
        shop.address2 || null,
        shop.postalCode ? Number.parseInt(shop.postalCode) : undefined,
        shop.city || undefined,
        shop.houseNumber || null,
        shop.openingHours || undefined,
        linkedShopIds,
        activeEnterprise?.cbeNumber ?? null,
        shop.separateRoom,
        shop.salesmanId
      );
    }

    // Create persons
    const createdPersons = await personUtilService.createPersons([
      ...personsToCreateInShop,
      ...personsToCreateInEnterprise,
    ]);

    // Link person in shop
    const toLinkInShop = [
      ...personsToLinkInShop.map((personInShop) => ({
        role: personInShop.role,
        personId: personInShop.person.id,
      })),
      ...personsToCreateInShop.map((val, index) => createdPersons[index]),
    ];
    if (toLinkInShop.length > 0) {
      await shopService.linkPersons(updatedShop.id, {
        persons: toLinkInShop,
      });
    }

    // Link person in enterprise
    if (activeEnterprise !== null) {
      const toLinkInEnterprise = [
        ...personsToLinkInEnterprise.map((personInEnterprise) => ({
          role: personInEnterprise.role,
          personId: personInEnterprise.person.id,
        })),
        ...personsToCreateInEnterprise.map(
          (val, index) => createdPersons[personsToCreateInShop.length + index]
        ),
      ];
      if (toLinkInEnterprise.length > 0) {
        await enterpriseService.linkPersons(activeEnterprise.cbeNumber, {
          persons: toLinkInEnterprise,
        });
      }

      // Update persons in enterprise
      if (personsToUpdateInEnterprise) {
        await Promise.all(
          personsToUpdateInEnterprise.map((personToUpdate) => {
            const personInEnterprise = activeEnterprise.persons?.find(
              (person) => person.person.id === personToUpdate.person.id
            );
            if (personInEnterprise) {
              return enterpriseService.updateLinkPerson(
                activeEnterprise.cbeNumber,
                personToUpdate.person.id,
                personInEnterprise?.role,
                personToUpdate.role
              );
            } else {
              return Promise.resolve();
            }
          })
        );
      }
    }

    // Update persons in shop
    if (personsToUpdateInShop) {
      await Promise.all(
        personsToUpdateInShop.map((personToUpdate) => {
          const personInShop = updatedShop.persons?.find(
            (person) => person.person.id === personToUpdate.person.id
          );
          if (personInShop) {
            return shopService.updateLinkPerson(
              updatedShop.id,
              personToUpdate.person.id,
              personInShop?.role,
              personToUpdate.role
            );
          } else {
            return Promise.resolve();
          }
        })
      );
    }

    return updatedShop;
  }
}
