import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { GamblingCommitteePaginatedOutDTO } from "../models/client/gambling-committee-paginated.out.dto";
import { GamblingCommitteeQueryFilterDTO } from "../models/client/gambling-committee-query-filter.dto";
import { GamblingCommitteeOutDTO } from "../models/client/gambling-committee.out.dto";
import { LicenceStatus } from "../models/client/type/licence-status.enum";
import { Lang } from "@/utils/lang.enum";
import { LicenceActor } from "../models/client/type/licence-actor.enum";
import { LicenceFinancing } from "../models/client/type/licence-financing.type";

export class GamblingCommitteeService {
  constructor(private methods: HttpMethod) {}

  async create(
    clientId: string,
    licencePrice: number,
    licenceRequester: LicenceActor,
    licencePayer: LicenceActor,
    documentIds?: string[],
    licenceFinancing?: LicenceFinancing,
    licenceRequesterOtherOperator?: string,
    licencePayerOtherOperator?: string,
    licenceNumber?: string,
    requestLicenceDate?: string,
    licenceStartDate?: string,
    licencePaymentDate?: string,
    licenceExpirationDate?: string,
    licenceStatus?: LicenceStatus,
    extraInfos?: Record<string, unknown>
  ) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.post(
      `/client/${clientId}/gambling-committee`,
      {
        licencePrice,
        documents: documentIds,
        licenceRequester,
        licencePayer,
        licenceFinancing,
        licenceRequesterOtherOperator,
        licencePayerOtherOperator,
        licenceNumber,
        requestLicenceDate,
        licenceStartDate,
        licencePaymentDate,
        licenceExpirationDate,
        licenceStatus,
        extraInfos,
      }
    );
    return plainToInstance(GamblingCommitteeOutDTO, result);
  }

  async update(
    gamblingCommitteeId: string,
    clientId: string,
    licencePrice: number,
    licenceRequester?: LicenceActor,
    licencePayer?: LicenceActor,
    documentIds?: string[],
    licenceFinancing?: LicenceFinancing,
    licenceRequesterOtherOperator?: string,
    licencePayerOtherOperator?: string,
    licenceNumber?: string,
    requestLicenceDate?: string | null,
    licencePaymentDate?: string | null,
    licenceStartDate?: string | null,
    licenceExpirationDate?: string | null,
    licenceStatus?: LicenceStatus,
    extraInfos?: Record<string, unknown>
  ) {
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch(
      `/client/${clientId}/gambling-committee/${gamblingCommitteeId}`,
      {
        licencePrice,
        documents: documentIds,
        licenceRequester,
        licencePayer,
        licenceFinancing,
        licenceRequesterOtherOperator,
        licencePayerOtherOperator,
        licenceNumber,
        requestLicenceDate,
        licenceStartDate,
        licencePaymentDate,
        licenceExpirationDate,
        licenceStatus,
        extraInfos,
      }
    );
    return plainToInstance(GamblingCommitteeOutDTO, result);
  }

  @memoize(10000, "GamblingCommitteeService")
  async list(
    offset?: number,
    limit?: number,
    filter?: GamblingCommitteeQueryFilterDTO
  ) {
    const params = plainToInstance(GamblingCommitteePaginatedOutDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
    });
    const result = await this.methods.get<GamblingCommitteePaginatedOutDTO>(
      `/gambling-committee`,
      {
        params,
      }
    );
    return plainToInstance(GamblingCommitteePaginatedOutDTO, result);
  }

  async export(
    filter?: GamblingCommitteeQueryFilterDTO,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/gambling-committee/export`, {
      params: {
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }
}
