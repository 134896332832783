import { Type } from "class-transformer";
import { ContractSummaryOutDTO } from "../contract/contract-summary.out.dto";
import { DocumentOutDTO } from "../document/document.out.dto";
import { EnvelopeOutDTO } from "../docusign/envelope.out.dto";
import { ShopOutDTO } from "../shop/shop.out.dto";
import { UserOutDTO } from "../user/user.out";
import { ClientSummaryOutDTO } from "./client-summary.out.dto";
import { GamblingCommitteeOutDTO } from "./gambling-committee.out.dto";
import { CashCardCommission } from "./type/cash-card-commission.interface";
import { ClientSituation } from "./type/client-situation.enum";
import { ClientStatus } from "./type/client-status.enum";
import { ClientType } from "./type/client-type.enum";
import { Commission } from "./type/commission.interface";
import { RacingCommission } from "./type/racing-commission.interface";
import { EnterpriseOutDTO } from "../enterprise/enterprise.out.dto";
import { getClientStatusColor } from "./utils/client-status-color";
import { ClientPeriodicity } from "./type/client-periodicity.enum";

export class ClientOutDTO implements ClientSummaryOutDTO {
  id!: string;
  shopId!: string;
  enterpriseId!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  status!: ClientStatus;
  type!: ClientType;
  situation!: ClientSituation[];
  vincennesId?: string;
  stereoluxId1?: string;
  stereoluxId2?: string;
  distributor?: string;
  brand?: string;
  sfkContractNumber?: string;
  contractDurationInMonth!: number;
  exclusivity!: boolean;
  periodicity!: ClientPeriodicity;
  feesAndTaxesRatio!: number;
  weekNegativeReport!: number;
  additionalOperators?: string[];

  @Type(() => ShopOutDTO)
  shop!: ShopOutDTO;

  @Type(() => EnterpriseOutDTO)
  enterprise!: EnterpriseOutDTO;

  @Type(() => DocumentOutDTO)
  documents!: DocumentOutDTO[];

  @Type(() => ContractSummaryOutDTO)
  contracts!: ContractSummaryOutDTO[];

  @Type(() => EnvelopeOutDTO)
  envelopes?: EnvelopeOutDTO[];

  @Type(() => UserOutDTO)
  users!: UserOutDTO[];

  @Type(() => GamblingCommitteeOutDTO)
  gamblingCommittee!: GamblingCommitteeOutDTO[];

  @Type(() => GamblingCommitteeOutDTO)
  lastGamblingCommittee!: GamblingCommitteeOutDTO;

  @Type(() => Number)
  financialAmount?: number;

  @Type(() => Number)
  financialLines?: number;

  commission?: Commission;
  greyhoundsCommission?: RacingCommission;
  horseRacingCommission?: RacingCommission;
  cashCardCommission?: CashCardCommission;
  managedClient!: boolean;
  licenceAtPartner!: boolean;

  public get statusColor() {
    return getClientStatusColor(this.status);
  }
}
