import "reflect-metadata";
import { InterventionSummaryListOutDTO } from "@/api/models/installation/intervention-summary-list.out.dto";
import { plainToInstance } from "class-transformer";
import { ShopOutDTO } from "@/api/models/shop/shop.out.dto";
import Vue from "vue";

const state = {
  shops: undefined as undefined | ShopOutDTO[],
  selectedInterventions: undefined as
    | undefined
    | InterventionSummaryListOutDTO[],
};
export type ShopMapState = typeof state;

enum localStorageKeys {
  SHOPS_ON_MAP = "shopsOnMap",
}

export const shopMapStore = {
  namespaced: true,
  state,
  mutations: {
    setMapState(state: ShopMapState, payload: ShopMapState) {
      Vue.set(state, "shops", payload.shops);
      Vue.set(state, "selectedInterventions", payload.selectedInterventions);
      localStorage.setItem(
        localStorageKeys.SHOPS_ON_MAP,
        JSON.stringify(state)
      );
    },
    clear(state: ShopMapState) {
      localStorage.removeItem(localStorageKeys.SHOPS_ON_MAP);
      Vue.set(state, "shops", undefined);
      Vue.set(state, "selectedInterventions", undefined);
    },
  },
  getters: {
    shops: (): ShopOutDTO[] | undefined => {
      const storedState = localStorage.getItem(localStorageKeys.SHOPS_ON_MAP);
      if (storedState) {
        const parsedState: ShopMapState = JSON.parse(storedState);
        state.shops =
          parsedState && parsedState?.shops
            ? parsedState.shops.map((shop) => plainToInstance(ShopOutDTO, shop))
            : undefined;
      } else {
        state.shops = undefined;
      }
      return state.shops;
    },
    selectedInterventions: (): InterventionSummaryListOutDTO[] | undefined => {
      const storedState = localStorage.getItem(localStorageKeys.SHOPS_ON_MAP);
      if (storedState) {
        const parsedState: ShopMapState = JSON.parse(storedState);
        state.selectedInterventions =
          parsedState && parsedState.selectedInterventions
            ? parsedState.selectedInterventions.map((intervention) =>
                plainToInstance(InterventionSummaryListOutDTO, intervention)
              )
            : undefined;
      } else {
        state.selectedInterventions = undefined;
      }
      return state.selectedInterventions;
    },
  },
};
