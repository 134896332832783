import { Type } from "class-transformer";
import { TicketStatus } from "./type/ticket-status.enum";

export class CallCenterTicketCountByStatusOutDTO {
  status!: TicketStatus;
  total!: number;
}

export class CallCenterTicketStatisticsOutDTO {
  @Type(() => CallCenterTicketCountByStatusOutDTO)
  countByStatus!: CallCenterTicketCountByStatusOutDTO[];
}
