import { LegalPersonJuridicalForm } from "./legal-person-juridical-form.enum";

export class EnterpriseSummaryOutDTO {
  cbeNumber!: string;
  name!: string;
  juridicalForm!: LegalPersonJuridicalForm;
  postalCode?: number;
  city?: string;
  province!: string;
  region!: string;
}
