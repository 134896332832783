import { Type } from "class-transformer";
import { ClientSummaryOutDTO } from "./client-summary.out.dto";
import { LicenceStatus } from "./type/licence-status.enum";

export class GamblingCommitteeSummaryListOutDTO {
  id!: string;

  @Type(() => ClientSummaryOutDTO)
  client!: ClientSummaryOutDTO;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  clientRequestLicence!: boolean; // if false => sagevas request licence
  licenceNumber?: string;

  @Type(() => Date)
  requestLicenceDate?: Date;

  @Type(() => Date)
  licenceStartDate?: Date;

  @Type(() => Date)
  licenceExpirationDate?: Date;

  licenceStatus!: LicenceStatus;
  extraInfos?: Record<string, unknown>;
}
