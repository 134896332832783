
import Component from "vue-class-component";
import Vue from "vue";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { SMSFieldsData } from "@/api/models/sms/sms-fields-data";
import { plainToInstance } from "class-transformer";
import { formRules } from "@/utils/form-rules";
import { translateWithRequired } from "@/utils/transform-translate";
import { ReceiverItem } from "@/api/models/sms/type/receiver-item.interface";

@Component
export default class SMSFields extends Vue {
  @Prop({ type: SMSFieldsData, required: true })
  public readonly SMSFieldsData!: SMSFieldsData;

  @Prop({ type: Array, required: false })
  public readonly receiverItems!: ReceiverItem[];

  @Watch("SMSFieldsData", { immediate: true })
  SMSFieldsChanged(SMSFields: SMSFieldsData | null) {
    if (SMSFields) {
      this.lazySMSFieldsData = plainToInstance(SMSFieldsData, SMSFields);
    } else {
      this.lazySMSFieldsData = null;
    }
  }

  @Emit("update:SMSFieldsData")
  public emitSMSFieldsDataUpdate() {
    return plainToInstance(SMSFieldsData, { ...this.lazySMSFieldsData });
  }

  public lazySMSFieldsData: SMSFieldsData | null = null;
  public search: string | null = null;
  public invalidPhones: string[] = [];
  public formRules = formRules;
  public translateWithRequired = translateWithRequired;

  public receiversChanged(values: (ReceiverItem | string)[]) {
    this.invalidPhones = [];
    for (const value of values) {
      if (
        typeof value === "string" &&
        formRules.internationalPhone(value) !== true
      ) {
        this.invalidPhones.push(value);
      }
    }
    this.emitSMSFieldsDataUpdate();
  }

  public isValidPhone(phone: string) {
    return phone && formRules.internationalPhone(phone) === true;
  }

  public allPhoneValid() {
    return this.invalidPhones.length === 0 || this.$tc("rules.phone");
  }
}
