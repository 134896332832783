import { Type } from "class-transformer";
import { DocumentOutDTO } from "../document/document.out.dto";
import { LicenceStatus } from "./type/licence-status.enum";
import { LicenceFinancing } from "./type/licence-financing.type";
import { LicenceActor } from "./type/licence-actor.enum";

export class GamblingCommitteeOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => DocumentOutDTO)
  documents!: DocumentOutDTO[];

  licencePrice!: number;
  licenceFinancing!: LicenceFinancing;
  licenceRequester!: LicenceActor;
  licencePayer!: LicenceActor;
  licenceRequesterOtherOperator?: string;
  licencePayerOtherOperator?: string;
  licenceNumber?: string;

  @Type(() => Date)
  licencePaymentDate?: Date;

  @Type(() => Date)
  requestLicenceDate?: Date;

  @Type(() => Date)
  licenceStartDate?: Date;

  @Type(() => Date)
  licenceExpirationDate?: Date;

  licenceStatus!: LicenceStatus;
  extraInfos?: Record<string, unknown>;

  get color() {
    switch (this.licenceStatus) {
      case LicenceStatus.WaitingForClientSignature:
      case LicenceStatus.SignedByClient:
      case LicenceStatus.WaitingForCommissionFeedback:
        return "info";
      case LicenceStatus.Accepted:
        return "success";
      case LicenceStatus.Cancelled:
        return "warning";
      case LicenceStatus.Expired:
      case LicenceStatus.Refused:
        return "error";
      default:
      case LicenceStatus.Draft:
        return "";
    }
  }
}
