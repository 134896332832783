import { Province } from "@/utils/province.enum";
import { Region } from "@/utils/region.enum";
import { Type } from "class-transformer";
import { CoordinatesOutDTO } from "./coordinates.out.dto";

export class PostalCodeInfoOutDTO {
  city!: string;

  @Type(() => CoordinatesOutDTO)
  coordinates!: CoordinatesOutDTO;

  region!: Region;
  province!: Province;
}
