export enum TicketFieldType {
  Date = "date",
  DateTime = "dateTime",
  Email = "email",
  File = "file",
  Info = "info",
  Number = "number",
  Select = "select",
  Text = "text",
  TextArea = "textArea",
  Phone = "phone",
}
