import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { CallCenterClientTicketQueryDTO } from "../models/call-center/call-center-client-ticket-query.dto";
import { CallCenterQueryPaginatedDTO } from "../models/call-center/call-center-query-paginated.dto";
import { CallCenterTicketData } from "../models/call-center/call-center-ticket-data";
import { CallCenterTicketFileUploadOutDTO } from "../models/call-center/call-center-ticket-file-upload.out.dto";
import { CallCenterTicketPaginatedOutDTO } from "../models/call-center/call-center-ticket-paginated.out.dto";
import { CallCenterTicketQueryFilterDTO } from "../models/call-center/call-center-ticket-query-filter.dto";
import { CallCenterTicketQueryOrderDTO } from "../models/call-center/call-center-ticket-query-order.dto";
import { CallCenterTicketStatisticsOutDTO } from "../models/call-center/call-center-ticket-statistics.out.dto";
import { CallCenterTicketOutDTO } from "../models/call-center/call-center-ticket.out.dto";
import { TicketDefinitionOutDTO } from "../models/call-center/ticket-definition/ticket-definition.out.dto";
import { TicketStatus } from "../models/call-center/type/ticket-status.enum";
import { Lang } from "@/utils/lang.enum";
import { CallCenterTicketFileIntegrityOutDTO } from "../models/call-center/call-center-ticket-file-integrity.out.dto";

export class TicketFileIntegrityError extends Error {
  constructor(
    public ticketId: string,
    public ticketFileIntegrity: CallCenterTicketFileIntegrityOutDTO
  ) {
    super();
  }
}
export class CallCenterService {
  constructor(private methods: HttpMethod) {}

  @memoize(86400000, "CallCenterDefinitionService") // 24h
  async getTicketDefinition() {
    const result = await this.methods.get<TicketDefinitionOutDTO[]>(
      "/call-center/ticket-definition"
    );
    return plainToInstance(TicketDefinitionOutDTO, result);
  }

  async createTicket(
    type: string,
    clientId?: string,
    description?: string,
    data?: CallCenterTicketData
  ) {
    clearBucketMemoization("CallCenterService");
    const result = await this.methods.post<CallCenterTicketOutDTO>(
      `/call-center/ticket`,
      {
        type,
        clientId,
        description,
        data,
      }
    );
    return plainToInstance(CallCenterTicketOutDTO, result);
  }

  async createMeTicket(
    type: string,
    clientId?: string,
    description?: string,
    data?: CallCenterTicketData
  ) {
    clearBucketMemoization("CallCenterService");
    const result = await this.methods.post<CallCenterTicketOutDTO>(
      `/call-center/me/ticket`,
      {
        type,
        clientId,
        description,
        data,
      }
    );
    return plainToInstance(CallCenterTicketOutDTO, result);
  }

  async updateTicket(
    ticketId: string,
    assignedUserEmails?: string[],
    assignedCategories?: string[],
    description?: string,
    data?: CallCenterTicketData,
    status?: TicketStatus,
    type?: string,
    priority?: number
  ) {
    clearBucketMemoization("CallCenterService");

    const result = await this.methods.patch<CallCenterTicketOutDTO>(
      `/call-center/ticket/${ticketId}`,
      {
        assignedUserEmails,
        assignedCategories,
        description,
        data,
        status,
        type,
        priority,
      }
    );
    return plainToInstance(CallCenterTicketOutDTO, result);
  }

  @memoize(10000, "CallCenterService")
  async getTicket(ticketId: string, includeHistory = true) {
    const result = await this.methods.get<CallCenterTicketOutDTO>(
      `/call-center/ticket/${ticketId}`,
      {
        params: {
          includeHistory,
        },
      }
    );
    return plainToInstance(CallCenterTicketOutDTO, result);
  }

  async getTicketFileIntegrity(ticketId: string, callCounter: number) {
    const result = await this.methods.get<CallCenterTicketFileIntegrityOutDTO>(
      `/call-center/ticket/${ticketId}/file-integrity`,
      {
        params: { callCounter },
      }
    );
    return plainToInstance(CallCenterTicketFileIntegrityOutDTO, result);
  }

  @memoize(10000, "CallCenterService")
  async listTicket(
    offset?: number,
    limit?: number,
    filter?: CallCenterTicketQueryFilterDTO,
    order?: CallCenterTicketQueryOrderDTO
  ) {
    const params = plainToInstance(CallCenterQueryPaginatedDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/ticket`,
      {
        params,
      }
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  @memoize(10000, "CallCenterService")
  async listMyTicket(
    offset?: number,
    limit?: number,
    filter?: CallCenterTicketQueryFilterDTO,
    order?: CallCenterTicketQueryOrderDTO
  ) {
    const params = plainToInstance(CallCenterQueryPaginatedDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/me/ticket`,
      {
        params,
      }
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  @memoize(10000, "CallCenterService")
  async searchTicket(
    term: string,
    offset?: number,
    limit?: number,
    filter?: CallCenterTicketQueryFilterDTO,
    order?: CallCenterTicketQueryOrderDTO
  ) {
    const params = plainToInstance(CallCenterQueryPaginatedDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      term,
    });
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/ticket-search`,
      {
        params,
      }
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  @memoize(10000, "CallCenterService")
  async searchMyTicket(
    term: string,
    offset?: number,
    limit?: number,
    filter?: CallCenterTicketQueryFilterDTO,
    order?: CallCenterTicketQueryOrderDTO
  ) {
    const params = plainToInstance(CallCenterQueryPaginatedDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      term,
    });
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/me/ticket-search`,
      {
        params,
      }
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  async storeTicketFile(ticketId: string, file: File, fieldName: string) {
    const result = await this.methods.postFile(
      `/call-center/ticket/${ticketId}/file/${fieldName}`,
      {
        file,
      }
    );
    return plainToInstance(CallCenterTicketFileUploadOutDTO, result);
  }

  async storeMyTicketFile(ticketId: string, file: File, fieldName: string) {
    const result = await this.methods.postFile(
      `/call-center/me/ticket/${ticketId}/file/${fieldName}`,
      {
        file,
      }
    );
    return plainToInstance(CallCenterTicketFileUploadOutDTO, result);
  }

  async getTicketFile(ticketId: string, fileName: string) {
    return await this.methods.getRaw(
      `/call-center/ticket/${ticketId}/file/${fileName}`
    );
  }

  async deleteTicket(ticketId: string) {
    clearBucketMemoization("CallCenterService");
    await this.methods.delete(`/call-center/ticket/${ticketId}`);
  }

  @memoize(10000, "CallCenterClientService")
  async listClientTicket(
    clientId: string,
    filter?: CallCenterClientTicketQueryDTO
  ) {
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/client/${clientId}/ticket`,
      {
        params: {
          filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        },
      }
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  @memoize(10000, "CallCenterMyClientService")
  async listMyClientTicket(clientId: string) {
    const result = await this.methods.get<CallCenterTicketPaginatedOutDTO>(
      `/call-center/me/client/${clientId}/ticket`
    );
    return plainToInstance(CallCenterTicketPaginatedOutDTO, result);
  }

  async export(
    term?: string,
    filter?: CallCenterTicketQueryFilterDTO,
    order?: CallCenterTicketQueryOrderDTO,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/call-center/export`, {
      params: {
        term: term ? term : undefined,
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        order: order ? btoa(JSON.stringify(order)) : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }

  async getStatistics() {
    const result = await this.methods.get(`/call-center/statistics`);
    return plainToInstance(CallCenterTicketStatisticsOutDTO, result);
  }
}
