import { Type } from "class-transformer";
import { Lang } from "../../../utils/lang.enum";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";

export enum CoordinatesAccuracy {
  Precise = "precise",
  Approximate = "approximate",
}

export class ShopSummaryOutDTO {
  id!: string;
  ref!: number;
  pointOfSaleName!: string;
  establishmentName!: string;

  get establishmentNameWithoutBracket() {
    const matches = /^(\[\[)?(?<content>.*?)(\]\])?$/.exec(
      this.establishmentName
    );
    if (matches !== null && matches.groups?.content) {
      return matches.groups.content;
    }
    return this.establishmentName;
  }

  lang!: Lang;
  establishmentNumber?: string;
  active!: boolean;
  address1?: string;
  address2?: string;
  postalCode!: number;
  city!: string;
  houseNumber?: string;
  province!: string;
  region!: string;

  @Type(() => ClientSummaryOutDTO)
  clients?: ClientSummaryOutDTO[];
}
