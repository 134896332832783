import { createDecorator } from "vue-class-component";
import store from "./index";

export function StoreGetter(getterType: string) {
  return createDecorator((options, key) => {
    if (!options.computed) {
      options.computed = {};
    }
    options.computed[key] = () => {
      return store.getters[getterType];
    };
  });
}
