import { Lang } from "@/utils/lang.enum";
import { HttpMethod } from "../http-method.interface";
import { ClientAttachmentData } from "../models/client/form/client-attachment-data";
import { ClientFieldsData } from "../models/client/form/client-fields-data";
import { CommissionBasedOn } from "../models/client/type/commission-based-on.enum";
import { ContractType } from "../models/contract/contract-type.enum";
import { TemplatePartsOutDTO } from "../models/contract/template.out.dto";
import { UserFieldsData } from "../models/user/form/user-fields-data";
import { UserOutDTO } from "../models/user/user.out";
import { ClientService } from "../services/client.service";
import { UserService } from "../services/user.service";
import { LicenceActor } from "../models/client/type/licence-actor.enum";
import { ClientPeriodicity } from "../models/client/type/client-periodicity.enum";

export class ClientUtilService {
  constructor(private methods: HttpMethod) {}

  async createClientWithTemplate(
    clientData: ClientFieldsData,
    templateName: string,
    contractType: ContractType,
    contractStatus: string,
    contractLang: Lang,
    customFieldsData: Record<string, string | number | boolean>,
    attachmentData?: ClientAttachmentData,
    validFrom?: string,
    validUntil?: string
  ) {
    // Create Users
    const newUsers: UserOutDTO[] = await this.createUsers(
      clientData.usersEmailsToCreate
    );

    // Create client
    const clientService = new ClientService(this.methods);
    const client = await clientService.createWithTemplate(
      clientData.shopId,
      clientData.enterpriseId,
      [...newUsers.map((user) => user.email), ...clientData.usersEmails],
      clientData.type,
      clientData.situation,
      clientData.contractDurationInMonth,
      clientData.exclusivity ?? false,
      clientData.periodicity ?? ClientPeriodicity.Monthly,
      clientData.feesAndTaxesRatio,
      clientData.weekNegativeReport,
      contractType,
      contractStatus,
      templateName,
      contractLang,
      customFieldsData,
      clientData.managedClient ?? false,
      clientData.licenceAtPartner ?? false,
      clientData.gamblingCommitteeData?.licenceRequester as LicenceActor,
      clientData.gamblingCommitteeData?.licencePayer as LicenceActor,
      clientData.gamblingCommitteeData?.licencePrice,
      clientData.gamblingCommitteeData?.documentIds,
      clientData.gamblingCommitteeData?.licenceFinancing,
      clientData.gamblingCommitteeData?.licenceRequesterOtherOperator,
      clientData.gamblingCommitteeData?.licencePayerOtherOperator,
      clientData.gamblingCommitteeData?.licenceNumber,
      clientData.gamblingCommitteeData?.requestLicenceDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStartDate ?? undefined,
      clientData.gamblingCommitteeData?.licencePaymentDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceExpirationDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStatus,
      clientData.gamblingCommitteeData?.extraInfos,
      clientData.additionalOperators,
      clientData.commission?.type,
      clientData.commission?.type === CommissionBasedOn.GainDecreasing
        ? clientData.commission.levels
        : undefined,
      clientData.commission?.type === CommissionBasedOn.Stake ||
        clientData.commission?.type === CommissionBasedOn.GainPercentage
        ? clientData.commission.ratio
        : undefined,
      clientData.greyhoundsCommission?.type,
      clientData.greyhoundsCommission?.ratio,
      clientData.horseRacingCommission?.type,
      clientData.horseRacingCommission?.ratio,
      clientData.cashCardCommission?.feesRatio,
      clientData.cashCardCommission?.weeklyRatio,
      validFrom,
      validUntil,
      clientData.vincennesId,
      clientData.stereoluxId1,
      clientData.stereoluxId2,
      clientData.distributor ?? undefined,
      clientData.brand ?? undefined,
      clientData.sfkContractNumber ?? undefined
    );

    // Attachments
    if (attachmentData) {
      await this.joinAttachments(attachmentData.files, client.id);
    }

    return client;
  }

  async createClientWithFile(
    clientData: ClientFieldsData,
    fromFile: File,
    contractType: ContractType,
    contractStatus: string,
    attachmentData?: ClientAttachmentData,
    validFrom?: string,
    validUntil?: string,
    contractStatusAddendum?: Record<string, unknown>
  ) {
    // Create Users
    const newUsers: UserOutDTO[] = await this.createUsers(
      clientData.usersEmailsToCreate
    );

    // Create client
    const clientService = new ClientService(this.methods);
    const client = await clientService.createWithFile(
      clientData.shopId,
      clientData.enterpriseId,
      [...newUsers.map((user) => user.email), ...clientData.usersEmails],
      clientData.type,
      clientData.situation,
      clientData.contractDurationInMonth,
      clientData.exclusivity ?? false,
      clientData.periodicity ?? ClientPeriodicity.Monthly,
      clientData.feesAndTaxesRatio,
      clientData.weekNegativeReport,
      contractType,
      contractStatus,
      fromFile,
      clientData.managedClient ?? false,
      clientData.licenceAtPartner ?? false,
      clientData.gamblingCommitteeData?.licenceRequester as LicenceActor,
      clientData.gamblingCommitteeData?.licencePayer as LicenceActor,
      clientData.gamblingCommitteeData?.licencePrice,
      clientData.gamblingCommitteeData?.documentIds,
      clientData.gamblingCommitteeData?.licenceFinancing,
      clientData.gamblingCommitteeData?.licenceRequesterOtherOperator,
      clientData.gamblingCommitteeData?.licencePayerOtherOperator,
      clientData.gamblingCommitteeData?.licenceNumber,
      clientData.gamblingCommitteeData?.requestLicenceDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStartDate ?? undefined,
      clientData.gamblingCommitteeData?.licencePaymentDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceExpirationDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStatus,
      clientData.gamblingCommitteeData?.extraInfos,
      clientData.additionalOperators,
      clientData.commission?.type,
      clientData.commission?.type === CommissionBasedOn.GainDecreasing
        ? clientData.commission.levels
        : undefined,
      clientData.commission?.type === CommissionBasedOn.Stake ||
        clientData.commission?.type === CommissionBasedOn.GainPercentage
        ? clientData.commission.ratio
        : undefined,
      clientData.greyhoundsCommission?.type,
      clientData.greyhoundsCommission?.ratio,
      clientData.horseRacingCommission?.type,
      clientData.horseRacingCommission?.ratio,
      clientData.cashCardCommission?.feesRatio,
      clientData.cashCardCommission?.weeklyRatio,
      validFrom,
      validUntil,
      clientData.vincennesId,
      clientData.stereoluxId1,
      clientData.stereoluxId2,
      clientData.distributor ?? undefined,
      clientData.brand ?? undefined,
      contractStatusAddendum,
      clientData.sfkContractNumber ?? undefined
    );

    // Attachments
    if (attachmentData) {
      await this.joinAttachments(attachmentData.files, client.id);
    }

    return client;
  }

  async createClientWithPrefilled(
    clientData: ClientFieldsData,
    preFilledTemplateParts: TemplatePartsOutDTO,
    contractType: ContractType,
    contractStatus: string,
    templateName: string,
    templateLang: Lang,
    customFieldsData: Record<string, string | number | boolean>,
    attachmentData?: ClientAttachmentData,
    validFrom?: string,
    validUntil?: string
  ) {
    // Create Users
    const newUsers: UserOutDTO[] = await this.createUsers(
      clientData.usersEmailsToCreate
    );

    // Create client
    const clientService = new ClientService(this.methods);
    const client = await clientService.createWithPrefilled(
      clientData.shopId,
      clientData.enterpriseId,
      [...newUsers.map((user) => user.email), ...clientData.usersEmails],
      clientData.type,
      clientData.situation,
      clientData.contractDurationInMonth,
      clientData.exclusivity ?? false,
      clientData.periodicity ?? ClientPeriodicity.Monthly,
      clientData.feesAndTaxesRatio,
      clientData.weekNegativeReport,
      contractType,
      contractStatus,
      preFilledTemplateParts,
      templateName,
      templateLang,
      customFieldsData,
      clientData.managedClient ?? false,
      clientData.licenceAtPartner ?? false,
      clientData.gamblingCommitteeData?.licenceRequester as LicenceActor,
      clientData.gamblingCommitteeData?.licencePayer as LicenceActor,
      clientData.gamblingCommitteeData?.licencePrice,
      clientData.gamblingCommitteeData?.documentIds,
      clientData.gamblingCommitteeData?.licenceFinancing,
      clientData.gamblingCommitteeData?.licenceRequesterOtherOperator,
      clientData.gamblingCommitteeData?.licencePayerOtherOperator,
      clientData.gamblingCommitteeData?.licenceNumber,
      clientData.gamblingCommitteeData?.requestLicenceDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStartDate ?? undefined,
      clientData.gamblingCommitteeData?.licencePaymentDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceExpirationDate ?? undefined,
      clientData.gamblingCommitteeData?.licenceStatus,
      clientData.gamblingCommitteeData?.extraInfos,
      clientData.additionalOperators,
      clientData.commission?.type,
      clientData.commission?.type === CommissionBasedOn.GainDecreasing
        ? clientData.commission.levels
        : undefined,
      clientData.commission?.type === CommissionBasedOn.Stake ||
        clientData.commission?.type === CommissionBasedOn.GainPercentage
        ? clientData.commission.ratio
        : undefined,
      clientData.greyhoundsCommission?.type,
      clientData.greyhoundsCommission?.ratio,
      clientData.horseRacingCommission?.type,
      clientData.horseRacingCommission?.ratio,
      clientData.cashCardCommission?.feesRatio,
      clientData.cashCardCommission?.weeklyRatio,
      validFrom,
      validUntil,
      clientData.vincennesId,
      clientData.stereoluxId1,
      clientData.stereoluxId2,
      clientData.distributor ?? undefined,
      clientData.brand ?? undefined,
      clientData.sfkContractNumber ?? undefined
    );

    // Attachments
    if (attachmentData) {
      await this.joinAttachments(attachmentData.files, client.id);
    }

    return client;
  }

  async updateClient(
    clientData: ClientFieldsData,
    attachmentData?: ClientAttachmentData,
    shopId?: string | undefined,
    enterpriseId?: string | undefined
  ) {
    if (!clientData.id) {
      throw new Error("client id should not be empty");
    }

    // Create Users
    const newUsers: UserOutDTO[] = await this.createUsers(
      clientData.usersEmailsToCreate
    );

    // Update Client
    const clientService = new ClientService(this.methods);
    const client = await clientService.update(
      clientData.id,
      [...newUsers.map((user) => user.email), ...clientData.usersEmails],
      clientData.type,
      clientData.situation,
      clientData.contractDurationInMonth,
      clientData.exclusivity,
      clientData.periodicity,
      clientData.feesAndTaxesRatio,
      clientData.weekNegativeReport,
      clientData.managedClient,
      clientData.licenceAtPartner,
      clientData.additionalOperators,
      clientData.commission?.type,
      clientData.commission?.type === CommissionBasedOn.GainDecreasing
        ? clientData.commission.levels
        : undefined,
      clientData.commission?.type === CommissionBasedOn.Stake ||
        clientData.commission?.type === CommissionBasedOn.GainPercentage
        ? clientData.commission.ratio
        : undefined,
      clientData.greyhoundsCommission?.type,
      clientData.greyhoundsCommission?.ratio,
      clientData.horseRacingCommission?.type,
      clientData.horseRacingCommission?.ratio,
      clientData.cashCardCommission?.feesRatio,
      clientData.cashCardCommission?.weeklyRatio,
      clientData.vincennesId || null,
      clientData.stereoluxId1 || null,
      clientData.stereoluxId2 || null,
      clientData.distributor,
      clientData.brand,
      clientData.sfkContractNumber || null,
      shopId,
      enterpriseId
    );

    // Attachments
    if (attachmentData) {
      await this.joinAttachments(attachmentData.files, client.id);

      // Remove attachments
      if (
        attachmentData.attachmentToRemove &&
        attachmentData.attachmentToRemove.length > 0
      ) {
        await Promise.all(
          attachmentData.attachmentToRemove.map((attachmentId) =>
            clientService.removeDocument(client.id, attachmentId)
          )
        );
      }
    }

    return client;
  }

  private async createUsers(usersEmailsToCreate: UserFieldsData[]) {
    let newUsers: UserOutDTO[] = [];
    if (usersEmailsToCreate && usersEmailsToCreate.length > 0) {
      const userService = new UserService(this.methods);
      newUsers = await Promise.all(
        usersEmailsToCreate.map((userToCreate) =>
          userService.create(
            userToCreate.email,
            userToCreate.lang,
            userToCreate.category,
            userToCreate.displayedName
          )
        )
      );
    }
    return newUsers;
  }

  private async joinAttachments(files: File[], clientId: string) {
    const clientService = new ClientService(this.methods);
    if (files && files.length > 0) {
      await Promise.all(
        files.map((file) => clientService.appendDocument(clientId, file))
      );
    }
  }
}
