import { Lang } from "@/utils/lang.enum";
import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { CommissionBasedOn } from "../models/client/type/commission-based-on.enum";
import { ContractStatus } from "../models/contract/contract-status.enum";
import { ContractTemplatePaginatedOutDTO } from "../models/contract/contract-template-paginated.out.dto";
import { ContractTemplateQueryFilterDTO } from "../models/contract/contract-template-query-filter.dto";
import { ContractTemplateTarget } from "../models/contract/contract-template-type.enum";
import { ContractTemplateOutDTO } from "../models/contract/contract-template.out.dto";
import { ContractType } from "../models/contract/contract-type.enum";
import { ContractOutDTO } from "../models/contract/contract.out.dto";
import { CustomFields } from "../models/contract/custom-fields.type";
import { TemplateDTO } from "../models/contract/template.dto";
import { TemplatePartsOutDTO } from "../models/contract/template.out.dto";
import { readFileToB64 } from "../utils/read-file-utile.service";
import { ClientFieldsData } from "../models/client/form/client-fields-data";

export class ContractService {
  constructor(private methods: HttpMethod) {}

  /**
   * CONTRACT
   */
  async createContractWithTemplate(
    clientId: string,
    type: ContractType,
    status: string,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    validFrom?: string,
    validUntil?: string,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");
    const result = await this.methods.post<ContractOutDTO>(
      `/client/${clientId}/contract`,
      {
        type,
        status,
        statusAddendum,
        template: { name: templateName, lang, customFieldsValue },
        validFrom,
        validUntil,
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async createContractWithPrefilled(
    clientId: string,
    type: ContractType,
    status: string,
    parts: TemplatePartsOutDTO,
    name: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    statusAddendum?: Record<string, unknown>,
    margin?: string,
    font?: string,
    fontSize?: string
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");
    const result = await this.methods.post<ContractOutDTO>(
      `/client/${clientId}/contract`,
      {
        type,
        status,
        statusAddendum,
        preFilledTemplate: {
          parts,
          name,
          lang,
          customFieldsValue,
          margin,
          font,
          fontSize,
        },
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async createContractWithFile(
    clientId: string,
    type: ContractType,
    status: string,
    fromFile: File,
    validFrom?: string,
    validUntil?: string,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");

    const result = await this.methods.post<ContractOutDTO>(
      `/client/${clientId}/contract`,
      {
        type,
        status,
        statusAddendum,
        document: {
          documentData: await readFileToB64(fromFile),
          originalFileName: fromFile.name,
        },
        validFrom,
        validUntil,
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async createContractWithDocument(
    clientId: string,
    type: ContractType,
    status: string,
    documentId: string,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");

    const result = await this.methods.post<ContractOutDTO>(
      `/client/${clientId}/contract`,
      {
        type,
        status,
        statusAddendum,
        document: {
          documentId,
        },
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async updateContractWithTemplate(
    clientId: string,
    contractId: string,
    type: ContractType,
    status: string,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    validFrom?: string,
    validUntil?: string,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`,
      {
        type,
        status,
        statusAddendum,
        overwriteContract: {
          template: {
            name: templateName,
            lang,
            customFieldsValue,
          },
          validFrom,
          validUntil,
        },
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async updateContractWithPrefilled(
    clientId: string,
    contractId: string,
    type: ContractType,
    status: string,
    parts: TemplatePartsOutDTO,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>,
    statusAddendum?: Record<string, unknown>,
    margin?: string,
    font?: string,
    fontSize?: string
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`,
      {
        type,
        status,
        statusAddendum,
        overwriteContract: {
          preFilledTemplate: {
            parts,
            name: templateName,
            lang,
            customFieldsValue,
            margin,
            font,
            fontSize,
          },
        },
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async updateContractWithFile(
    clientId: string,
    contractId: string,
    type: ContractType,
    status: string,
    fromFile: File,
    validFrom?: string,
    validUntil?: string,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");

    const result = await this.methods.patch<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`,
      {
        type,
        status,
        statusAddendum,
        overwriteContract: {
          document: {
            documentData: await readFileToB64(fromFile),
            originalFileName: fromFile.name,
          },
          validFrom,
          validUntil,
        },
      }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async updateContractStatus(
    clientId: string,
    contractId: string,
    status: ContractStatus,
    statusAddendum?: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");

    const serverContractStatus =
      status === ContractStatus.Rescinded
        ? ContractStatus.ForcedRescind
        : status;

    const result = await this.methods.patch<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`,
      { status: serverContractStatus, statusAddendum }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async updateContractType(
    clientId: string,
    contractId: string,
    type: ContractType
  ) {
    clearBucketMemoization("ContractService");
    clearBucketMemoization("ClientService");

    const result = await this.methods.patch<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`,
      { type }
    );
    return plainToInstance(ContractOutDTO, result);
  }

  @memoize(10000, "ContractService")
  async getContract(clientId: string, contractId: string) {
    const result = await this.methods.get<ContractOutDTO>(
      `/client/${clientId}/contract/${contractId}`
    );
    return plainToInstance(ContractOutDTO, result);
  }

  async preFilledContract(
    clientId: string,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    const result = await this.methods.post<TemplatePartsOutDTO>(
      `/client/${clientId}/pre-filled/contract`,
      { name: templateName, lang, customFieldsValue }
    );
    return plainToInstance(TemplatePartsOutDTO, result);
  }

  async previewContract(
    clientId: string,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>
  ) {
    return await this.methods.post<string>(
      `/client/${clientId}/preview/contract`,
      { template: { name: templateName, lang, customFieldsValue } }
    );
  }

  async preFilledContractWithClientCreation(
    client: ClientFieldsData,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>
  ) {
    clearBucketMemoization("ContractService");
    const result = await this.methods.post<TemplatePartsOutDTO>(
      `/client/pre-filled/contract`,
      {
        client: {
          shopId: client.shopId,
          enterpriseId: client.enterpriseId,
          type: client.type,
          gamblingCommittee: client.gamblingCommitteeData,
          commission: client.commission,
          greyhoundsCommission: client.greyhoundsCommission,
          horseRacingCommission: client.horseRacingCommission,
          cashCardCommission: client.cashCardCommission,
          distributor: client.distributor,
          brand: client.brand,
          situation: client.situation,
          sfkContractNumber: client.sfkContractNumber,
          vincennesId: client.vincennesId,
          stereoluxId1: client.stereoluxId1,
          stereoluxId2: client.stereoluxId2,
          usersEmails: client.usersEmails,
          contractDurationInMonth: client.contractDurationInMonth,
          exclusivity: client.exclusivity,
          periodicity: client.periodicity,
          feesAndTaxesRatio: client.feesAndTaxesRatio,
          additionalOperators: client.additionalOperators,
          weekNegativeReport: client.weekNegativeReport,
          managedClient: client.managedClient,
          licenceAtPartner: client.licenceAtPartner,
        },
        template: { name: templateName, lang, customFieldsValue },
      }
    );
    return plainToInstance(TemplatePartsOutDTO, result);
  }

  async previewContractWithClientCreation(
    client: ClientFieldsData,
    templateName: string,
    lang: Lang,
    customFieldsValue: Record<string, unknown>
  ) {
    return await this.methods.post<string>(`/client/preview/contract`, {
      client: {
        shopId: client.shopId,
        enterpriseId: client.enterpriseId,
        type: client.type,
        gamblingCommittee: client.gamblingCommitteeData,
        commission: client.commission,
        greyhoundsCommission: client.greyhoundsCommission,
        horseRacingCommission: client.horseRacingCommission,
        cashCardCommission: client.cashCardCommission,
        distributor: client.distributor,
        brand: client.brand,
        situation: client.situation,
        sfkContractNumber: client.sfkContractNumber,
        vincennesId: client.vincennesId,
        stereoluxId1: client.stereoluxId1,
        stereoluxId2: client.stereoluxId2,
        usersEmails: client.usersEmails,
        contractDurationInMonth: client.contractDurationInMonth,
        exclusivity: client.exclusivity,
        periodicity: client.periodicity,
        feesAndTaxesRatio: client.feesAndTaxesRatio,
        additionalOperators: client.additionalOperators,
        weekNegativeReport: client.weekNegativeReport,
        managedClient: client.managedClient,
        licenceAtPartner: client.licenceAtPartner,
      },
      preview: { template: { name: templateName, lang, customFieldsValue } },
    });
  }

  /**
   * TEMPLATE
   */

  @memoize(10000, "ContractService")
  async getTemplateDetail(
    contractTemplateName: string,
    lang: Lang,
    version?: number
  ) {
    const result = await this.methods.get<ContractTemplateOutDTO>(
      `/contract-template/${contractTemplateName}/${lang}`,
      { params: version !== undefined ? { version } : {} }
    );
    return plainToInstance(ContractTemplateOutDTO, result);
  }

  @memoize(10000, "ContractService")
  async listTemplate(
    offset?: number,
    limit?: number,
    search?: string,
    filter?: ContractTemplateQueryFilterDTO
  ) {
    const result = await this.methods.get<ContractTemplatePaginatedOutDTO>(
      "/contract-template",
      {
        params: {
          offset,
          limit,
          search,
          filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        },
      }
    );
    return plainToInstance(ContractTemplatePaginatedOutDTO, result);
  }

  async createTemplate(
    name: string,
    lang: Lang,
    target: ContractTemplateTarget,
    contractType: ContractType,
    commissionType: CommissionBasedOn | null,
    template: TemplateDTO,
    header: TemplateDTO,
    footer: TemplateDTO,
    versoHeader?: TemplateDTO,
    versoFooter?: TemplateDTO,
    font?: string,
    fontSize?: number,
    margin?: string,
    customFields?: CustomFields
  ) {
    clearBucketMemoization("ContractService");
    const result = await this.methods.post<ContractTemplateOutDTO>(
      "/contract-template",
      {
        name,
        lang,
        target,
        contractType,
        commissionType,
        template,
        header,
        footer,
        versoHeader,
        versoFooter,
        font,
        fontSize: `${fontSize}px`,
        margin,
        customFields,
      }
    );
    return plainToInstance(ContractTemplateOutDTO, result);
  }

  async previewTemplate(
    commissionType: CommissionBasedOn | null,
    template: TemplateDTO,
    header: TemplateDTO,
    footer: TemplateDTO,
    versoHeader?: TemplateDTO,
    versoFooter?: TemplateDTO,
    font?: string,
    fontSize?: number,
    margin?: string,
    customFields?: CustomFields
  ) {
    const result = await this.methods.post<string>(
      "/contract-template/preview",
      {
        commissionType,
        template,
        header,
        footer,
        versoHeader,
        versoFooter,
        font,
        fontSize: `${fontSize}px`,
        margin,
        customFields,
      }
    );
    return result;
  }

  async updateTemplate(
    contractTemplateName: string,
    lang: Lang,
    target: ContractTemplateTarget,
    contractType: ContractType,
    commissionType: CommissionBasedOn | null,
    template: TemplateDTO,
    header: TemplateDTO,
    footer: TemplateDTO,
    versoHeader?: TemplateDTO,
    versoFooter?: TemplateDTO,
    font?: string,
    fontSize?: number,
    margin?: string,
    customFields?: CustomFields
  ) {
    clearBucketMemoization("ContractService");
    const result = await this.methods.patch<ContractTemplateOutDTO>(
      `/contract-template/${contractTemplateName}/${lang}`,
      {
        target,
        contractType,
        commissionType,
        template,
        header,
        footer,
        versoHeader,
        versoFooter,
        font,
        fontSize: `${fontSize}px`,
        margin,
        customFields,
      }
    );
    return plainToInstance(ContractTemplateOutDTO, result);
  }

  deleteTemplate(contractTemplateName: string, lang: Lang) {
    clearBucketMemoization("ContractService");
    return this.methods.delete(
      `/contract-template/${contractTemplateName}/${lang}`
    );
  }

  /**
   * ASSETS
   */

  async createAsset(
    file: File,
    fileName?: string,
    subPath?: string,
    override?: boolean
  ) {
    return this.methods.postFile("/contract-template/asset", {
      file,
      fileName,
      subPath,
      override,
    });
  }

  async listAsset() {
    return this.methods.get("/contract-template/asset");
  }

  async getAssetDetail(path: string) {
    return this.methods.get(`/contract-template/asset/${path}`);
  }

  async deleteAsset(path: string) {
    return this.methods.delete(`/contract-template/asset/${path}`);
  }
}
