import { Lang } from "@/utils/lang.enum";
import { Type } from "class-transformer";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { UserCategory } from "./user-category.enum";

export class UserOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => Date)
  deletedAt?: Date;

  @Type(() => ClientSummaryOutDTO)
  clients?: ClientSummaryOutDTO[];

  email!: string;
  category?: UserCategory;
  disabled!: boolean;
  displayedName!: string;
  confirmed!: boolean;
  lang!: Lang;
}
