export enum InterventionStatus {
  InstallationRequested = "installationRequested",
  InstallationToDo = "installationToDo",
  InstallationInProgress = "installationInProgress",
  /** = Ticket closed */
  InstallationCompleted = "installationCompleted",
  ToUninstall = "toUninstall",
  AddKiosk = "addKiosk",
  ToBeModified = "toBeModified",
  SwappToDo = "swappToDo",
  InstallationNotPossible = "installationNotPossible",
  ShopClosed = "shopClosed",
  HsNoPayment = "hsNoPayment",
  Support = "support",
}

export const CompletedInterventionStatus = [
  InterventionStatus.InstallationCompleted,
];
