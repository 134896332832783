import { ActionContext } from "vuex";

export class StoredNotification {
  public outdated = false;
  public visible = true;

  constructor(
    public message: string,
    public type: "success" | "info" | "warning" | "error",
    public dismissible: boolean = false,
    public autoCloseDelay: number = 5000
  ) {}
}

const state = {
  notifications: [] as StoredNotification[],
};
export type NotificationState = typeof state;
type NotificationContext<State> = ActionContext<NotificationState, State>;

export const notificationStore = {
  namespaced: true,
  state,
  mutations: {
    addNotification(state: NotificationState, payload: StoredNotification) {
      if (
        state.notifications.find(
          (error) => error.message === payload.message
        ) === undefined
      ) {
        state.notifications.push(payload);
      }
    },
    clear(state: NotificationState) {
      state.notifications = [];
    },
    clean(state: NotificationState) {
      state.notifications = state.notifications.filter(
        (notification) => !notification.outdated
      );
    },
  },
  actions: {
    addNotification<State>(
      context: NotificationContext<State>,
      payload: StoredNotification
    ) {
      context.commit("clean");
      context.commit("addNotification", payload);
      if (payload.autoCloseDelay > 0) {
        setTimeout(() => {
          payload.visible = false;
        }, payload.autoCloseDelay);

        // Wait for animation / transition
        setTimeout(() => {
          payload.outdated = true;
        }, payload.autoCloseDelay + 5000);
      }
    },
  },
  getters: {
    notifications(state: NotificationState) {
      return state.notifications;
    },
  },
};
