import { Lang } from "@/utils/lang.enum";
import { Type } from "class-transformer";
import { ContractTemplateTarget } from "./contract-template-type.enum";
import { ContractType } from "./contract-type.enum";

export class ContractTemplateSummaryOutDTO {
  @Type(() => Date)
  createdAt!: Date;

  name!: string;
  target!: ContractTemplateTarget;
  contractType!: ContractType;
  lang!: Lang;
  archived!: boolean;
  version!: number;
}
