
import { ClientSummaryOutDTO } from "@/api/models/client/client-summary.out.dto";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import store from "@/store";
import { StoreGetter } from "@/store/storeGetter.decorator";
import { VuetifyItem } from "@/utils/vuetify-item.interface";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class MyClientSwitch extends Vue {
  @StoreGetter("user/selectedClient") selectedClient!: ClientSummaryOutDTO;
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  public get clientItems(): VuetifyItem[] {
    if (!this.user.clients) {
      return [];
    }

    return this.user.clients.map((client) => ({
      text: client.shop?.pointOfSaleName || "?",
      value: client,
    }));
  }

  public onClientChange(client: ClientSummaryOutDTO) {
    store.commit("user/setSelectedClient", client);
  }
}
