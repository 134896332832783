import { Type } from "class-transformer";

export class DocumentSummaryOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  mimeType?: string;
  extension!: string;
  public!: boolean;
  clientId!: string;
  originalFileName!: string;
  category?: string;
  comment?: string;

  @Type(() => Date)
  validFrom!: Date;

  @Type(() => Date)
  validUntil!: Date;
}
