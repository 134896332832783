import Vue from "vue";
import VueI18n, { LocaleMessages, NumberFormats } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const numberFormats: NumberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    percent: {
      style: "percent",
      maximumFractionDigits: 2,
    },
    days: {
      style: "unit",
      unit: "day",
      unitDisplay: "long",
    },
  },
  fr: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    days: {
      style: "unit",
      unit: "day",
      unitDisplay: "long",
    },
  },
  nl: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    days: {
      style: "unit",
      unit: "day",
      unitDisplay: "long",
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  numberFormats,
});
