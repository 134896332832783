
import Vue from "vue";
import Component from "vue-class-component";
import LangMenu from "@/components/LangMenu.vue";
import { StoreGetter } from "@/store/storeGetter.decorator";
import { Lang } from "@/utils/lang.enum";

@Component({
  components: {
    LangMenu,
  },
})
export default class AuthenticateLayout extends Vue {
  @StoreGetter("user/lang") lang!: Lang;

  mounted() {
    this.$i18n.locale = this.lang;
    this.$vuetify.theme.dark = false;
  }
}
