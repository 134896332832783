import { Type } from "class-transformer";
import { MailHistoryAttachmentOutDTO } from "./mail-history-attachement-out.dto";

export class MailHistoryOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  from!: string;
  subject?: string;
  to?: string[];
  bcc?: string[];
  html?: string;
  text?: string;

  @Type(() => MailHistoryAttachmentOutDTO)
  attachmentFiles!: MailHistoryAttachmentOutDTO[];
}
