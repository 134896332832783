import { Type } from "class-transformer";
import { UserOutDTO } from "../user/user.out";
import { ContractChangeLogLog } from "./type/contract-change-log-log.type";

export class ContractChangeLogOutDTO {
  @Type(() => Date)
  createdAt!: Date;

  log?: ContractChangeLogLog;

  @Type(() => UserOutDTO)
  editor?: UserOutDTO;
}
