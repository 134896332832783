import { Type } from "class-transformer";
import { RightOutDTO } from "./right.out";

export class RoleOutDTO {
  name!: string;

  @Type(() => RightOutDTO)
  rights?: RightOutDTO[];

  @Type(() => Date)
  createdAt!: Date;
}
