import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { SMSHistoryPaginatedOutDTO } from "../models/sms/sms-history-paginated.out.dto";
import { SMSHistoryQueryOrderDTO } from "../models/sms/sms-history-query-order.dto";
import { SMSHistorySearchPaginatedDTO } from "../models/sms/sms-history-search-paginated.dto";

export class SMSService {
  constructor(private methods: HttpMethod) {}

  send(receivers: string[], message: string) {
    clearBucketMemoization("SMSService");
    return this.methods.post("/sms/send", { receivers, message });
  }

  @memoize(10000, "SMSService")
  async search(
    term?: string,
    offset?: number,
    limit?: number,
    order?: SMSHistoryQueryOrderDTO
  ) {
    const params = plainToInstance(SMSHistorySearchPaginatedDTO, {
      offset,
      limit,
      term,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<SMSHistoryPaginatedOutDTO>(
      `/sms/history`,
      {
        params,
      }
    );
    return plainToInstance(SMSHistoryPaginatedOutDTO, result);
  }
}
