import { Type } from "class-transformer";
import { Lang } from "../../../utils/lang.enum";
import { CoordinatesOutDTO } from "../coordinates.out.dto";
import { EnterpriseOutDTO } from "../enterprise/enterprise.out.dto";
import { OpeningHoursOutDTO } from "../opening-hours.out.dto";
import { ShopSummaryOutDTO } from "./shop-summary.out.dto";
import { PersonInShopOutDTO } from "../shop/person-in-shop-out.dto";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { UserSummaryOutDTO } from "../user/user-summary.out.dto";

export enum CoordinatesAccuracy {
  Precise = "precise",
  Approximate = "approximate",
}

export class ShopOutDTO {
  id!: string;
  ref!: number;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  pointOfSaleName!: string;
  establishmentName!: string;

  get establishmentNameWithoutBracket() {
    const matches = /^(\[\[)?(?<content>.*?)(\]\])?$/.exec(
      this.establishmentName
    );
    if (matches !== null && matches.groups?.content) {
      return matches.groups.content;
    }
    return this.establishmentName;
  }

  lang!: Lang;
  establishmentNumber = "";
  active!: boolean;
  phones: string[] = [];
  emails: string[] = [];

  @Type(() => CoordinatesOutDTO)
  coordinates?: CoordinatesOutDTO;

  coordinatePrecision!: CoordinatesAccuracy;

  address1?: string;
  address2?: string;
  postalCode!: number;
  city!: string;
  houseNumber?: string;

  province!: string;
  region!: string;
  separateRoom!: boolean;

  @Type(() => OpeningHoursOutDTO)
  openingHours: OpeningHoursOutDTO = {
    mon: undefined,
    tue: undefined,
    wed: undefined,
    thu: undefined,
    fri: undefined,
    sat: undefined,
    sun: undefined,
  };

  @Type(() => ShopSummaryOutDTO)
  linkedShops?: ShopSummaryOutDTO[];

  @Type(() => EnterpriseOutDTO)
  activeEnterprise?: EnterpriseOutDTO;

  @Type(() => PersonInShopOutDTO)
  persons?: PersonInShopOutDTO[];

  @Type(() => ClientSummaryOutDTO)
  clients?: ClientSummaryOutDTO[];

  @Type(() => UserSummaryOutDTO)
  salesman?: UserSummaryOutDTO;
}
