import { Type } from "class-transformer";

export class ClientFinancialOutDTO {
  type!: string;

  @Type(() => Date)
  date!: Date;

  amount!: number;
  text!: string;
}
