import { plainToInstance } from "class-transformer";
import { clearBucketMemoization, memoize } from "../../utils/memoize";
import { HttpMethod } from "../http-method.interface";
import { EnterprisePaginatedOutDTO } from "../models/enterprise/enterprise-paginated.out.dto";
import { EnterpriseQueryFilterDTO } from "../models/enterprise/enterprise-query-filter.dto";
import { EnterpriseQueryOrderDTO } from "../models/enterprise/enterprise-query-order.dto";
import { EnterpriseType } from "../models/enterprise/enterprise-type.enum";
import { EnterpriseOutDTO } from "../models/enterprise/enterprise.out.dto";
import { LegalPersonJuridicalForm } from "../models/enterprise/legal-person-juridical-form.enum";
import { PersonsInEnterpriseAddDTO } from "../models/enterprise/persons-in-enterprise-add.dto";
import { JuridicalSituation } from "../models/shop/type/juridical-situation.enum";
import { Lang } from "@/utils/lang.enum";

export class EnterpriseService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "EnterpriseService")
  async getDetail(cbeNumber: string) {
    const result = await this.methods.get<EnterpriseOutDTO>(
      `/enterprise/${cbeNumber}`
    );
    return plainToInstance(EnterpriseOutDTO, result);
  }

  @memoize(10000, "EnterpriseService")
  async list(
    offset?: number,
    limit?: number,
    filter?: EnterpriseQueryFilterDTO,
    order?: EnterpriseQueryOrderDTO
  ) {
    const result = await this.methods.get<EnterprisePaginatedOutDTO>(
      `/enterprise`,
      {
        params: {
          offset,
          limit,
          filter: filter ? btoa(JSON.stringify(filter)) : undefined,
          order: order ? btoa(JSON.stringify(order)) : undefined,
        },
      }
    );
    return plainToInstance(EnterprisePaginatedOutDTO, result);
  }

  @memoize(10000, "EnterpriseService")
  async search(
    term: string,
    offset?: number,
    limit?: number,
    filter?: EnterpriseQueryFilterDTO,
    order?: EnterpriseQueryOrderDTO
  ) {
    const params = plainToInstance(EnterprisePaginatedOutDTO, {
      offset,
      limit,
      term,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<EnterprisePaginatedOutDTO>(
      `/enterprise/search`,
      {
        params,
      }
    );
    return plainToInstance(EnterprisePaginatedOutDTO, result);
  }

  async create(
    cbeNumber: string,
    type: EnterpriseType,
    name: string,
    address1: string | undefined,
    address2: string | undefined,
    postalCode: number | undefined,
    city: string | undefined,
    houseNumber: string | undefined,
    juridicalForm: LegalPersonJuridicalForm | undefined,
    juridicalSituation: JuridicalSituation | undefined,
    bankAccountNumber: string | undefined
  ) {
    clearBucketMemoization("EnterpriseService");
    const result = await this.methods.post<EnterpriseOutDTO>(`/enterprise`, {
      cbeNumber,
      type,
      name,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      juridicalForm: type === EnterpriseType.Legal ? juridicalForm : undefined,
      juridicalSituation,
      bankAccountNumber,
    });
    return plainToInstance(EnterpriseOutDTO, result);
  }

  async update(
    cbeNumber: string,
    name: string,
    address1: string | undefined,
    address2: string | undefined,
    postalCode: number | undefined,
    city: string | undefined,
    houseNumber: string | undefined,
    juridicalForm: LegalPersonJuridicalForm | undefined,
    juridicalSituation: JuridicalSituation | undefined,
    bankAccountNumber: string | undefined,
    type: EnterpriseType | undefined
  ) {
    clearBucketMemoization("EnterpriseService");
    const enterpriseData = {
      name,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      juridicalForm,
      type,
      juridicalSituation,
      bankAccountNumber,
    };
    let result: EnterpriseOutDTO;
    if (type !== undefined) {
      result = await this.methods.patch<EnterpriseOutDTO>(
        `/enterprise/admin/${cbeNumber}`,
        enterpriseData
      );
    } else {
      result = await this.methods.patch<EnterpriseOutDTO>(
        `/enterprise/${cbeNumber}`,
        enterpriseData
      );
    }

    return plainToInstance(EnterpriseOutDTO, result);
  }

  delete(cbeNumber: string) {
    clearBucketMemoization("EnterpriseService");
    return this.methods.delete(`/enterprise/${cbeNumber}`);
  }

  linkPersons(cbeNumber: string, persons: PersonsInEnterpriseAddDTO) {
    clearBucketMemoization("EnterpriseService");
    return this.methods.post(`/enterprise/${cbeNumber}/person/link`, persons);
  }

  deleteLinkPerson(cbeNumber: string, personId: string, role?: string) {
    clearBucketMemoization("EnterpriseService");
    return this.methods.delete(
      `/enterprise/${cbeNumber}/person/link/${personId}${
        role ? `/${role}` : ""
      }`
    );
  }

  updateLinkPerson(
    cbeNumber: string,
    personId: string,
    role?: string,
    newRole?: string
  ) {
    clearBucketMemoization("EnterpriseService");
    return this.methods.patch(
      `/enterprise/${cbeNumber}/person/link/${personId}${
        role ? `/${role}` : ""
      }`,
      {
        role: newRole ?? null,
      }
    );
  }

  async export(
    term?: string,
    order?: EnterpriseQueryOrderDTO,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/enterprise/export`, {
      params: {
        term: term ? term : undefined,
        order: order ? btoa(JSON.stringify(order)) : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }
}
