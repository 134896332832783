import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { UserActivationStatus } from "../models/user/user-activation-status.enum";
import { UserWithRightOutDTO } from "../models/user/user-with-right.out";
import { UserOutDTO } from "../models/user/user.out";
import { UserPaginatedOutDTO } from "../models/user/user-paginated.out.dto";
import { Lang } from "@/utils/lang.enum";
import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { UserCategory } from "../models/user/user-category.enum";
import { UserQueryFilterDTO } from "../models/user/user-query-filter.dto";
import { UserQueryOrderDTO } from "../models/user/user-query-order.dto";

export class UserService {
  constructor(private methods: HttpMethod) {}

  async me() {
    const result = await this.methods.get<UserWithRightOutDTO>("/user/me");
    return plainToInstance(UserWithRightOutDTO, result);
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const result = await this.methods.patch<UserOutDTO>(
      "/user/password/change",
      {
        oldPassword,
        newPassword,
      }
    );
    return plainToInstance(UserOutDTO, result);
  }

  async askResetPassword(email: string, type: "reset" | "new" = "reset") {
    await this.methods.post<void>(`/user/password/ask-reset/${email}`, {
      type,
    });
  }

  async resetPassword(token: string, newPassword: string) {
    await this.methods.patch<void>("/user/password/reset", {
      token,
      newPassword,
    });
  }

  @memoize(10000, "UserService")
  async getDetail(email: string) {
    const result = await this.methods.get<UserWithRightOutDTO>(
      `/user/${email}`
    );
    return plainToInstance(UserWithRightOutDTO, result);
  }

  @memoize(10000, "UserService")
  async list(
    offset?: number,
    limit?: number,
    disabled?: number,
    search?: string,
    filter?: UserQueryFilterDTO
  ) {
    const result = await this.methods.get<UserPaginatedOutDTO>(`/user`, {
      params: {
        offset,
        limit,
        disabled,
        search,
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      },
    });
    return plainToInstance(UserPaginatedOutDTO, result);
  }

  @memoize(10000, "UserService")
  async listInternal(
    category: UserCategory[],
    offset?: number,
    limit?: number,
    order?: UserQueryOrderDTO
  ) {
    const result = await this.methods.get<UserPaginatedOutDTO>(
      `/user/internal`,
      {
        params: {
          category,
          offset,
          limit,
          order,
        },
      }
    );
    return plainToInstance(UserPaginatedOutDTO, result);
  }

  async create(
    email: string,
    lang: Lang,
    category?: UserCategory,
    displayedName?: string
  ) {
    clearBucketMemoization("UserService");
    const result = await this.methods.post<UserWithRightOutDTO>(`/user`, {
      email,
      lang,
      category,
      displayedName,
    });
    return plainToInstance(UserOutDTO, result);
  }

  async setActivation(email: string, status: UserActivationStatus) {
    clearBucketMemoization("UserService");
    await this.methods.patch<void>(`/user/${email}/activation`, {
      status,
    });
  }

  async setRight(email: string, rights: string[]) {
    clearBucketMemoization("UserService");
    await this.methods.patch<void>(`/user/${email}/right`, {
      rights,
    });
  }

  async delete(email: string) {
    clearBucketMemoization("UserService");
    await this.methods.delete<void>(`/user/${email}`);
  }

  async changeLang(email: string, lang: Lang) {
    clearBucketMemoization("UserService");
    await this.methods.patch(`/user/${email}/lang`, {
      lang,
    });
  }

  async changeMyLang(lang: Lang) {
    clearBucketMemoization("UserService");
    await this.methods.patch(`/user/lang`, {
      lang,
    });
  }

  async setCategory(email: string, category?: UserCategory) {
    clearBucketMemoization("UserService");
    await this.methods.patch(`/user/${email}/category`, {
      category,
    });
  }

  async setDisplayedName(email: string, displayedName: string) {
    clearBucketMemoization("UserService");
    await this.methods.patch(`/user/${email}/displayed-name`, {
      displayedName,
    });
  }
}
