import { Lang } from "@/utils/lang.enum";
import { plainToInstance } from "class-transformer";
import { clearBucketMemoization, memoize } from "../../utils/memoize";
import { HttpMethod } from "../http-method.interface";
import { CoordinatesOutDTO } from "../models/coordinates.out.dto";
import { OpeningHoursOutDTO } from "../models/opening-hours.out.dto";
import { PersonsInShopAddDTO } from "../models/shop/persons-in-shop-add.dto";
import { ShopCoordinatesOutDTO } from "../models/shop/shop-coordinates.dto";
import { ShopPaginatedOutDTO } from "../models/shop/shop-paginated.out.dto";
import { ShopQueryFilterDTO } from "../models/shop/shop-query-filter.dto";
import { ShopQueryOrderDTO } from "../models/shop/shop-query-order.dto";
import { ShopSearchPaginatedDTO } from "../models/shop/shop-search-paginated.dto";
import { ShopOutDTO } from "../models/shop/shop.out.dto";

export class ShopService {
  constructor(private methods: HttpMethod) {}

  @memoize(1800000, "ShopService")
  async getDetail(shopId: string) {
    const result = await this.methods.get<ShopOutDTO>(`/shop/${shopId}`);
    return plainToInstance(ShopOutDTO, result);
  }

  @memoize(1800000, "ShopService")
  async list(
    offset?: number,
    limit?: number,
    filter?: ShopQueryFilterDTO,
    order?: ShopQueryOrderDTO
  ) {
    const result = await this.methods.get<ShopPaginatedOutDTO>(`/shop`, {
      params: {
        offset,
        limit,
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        order: order ? btoa(JSON.stringify(order)) : undefined,
      },
    });
    return plainToInstance(ShopPaginatedOutDTO, result);
  }

  @memoize(10000, "ShopService")
  async search(
    term: string,
    offset?: number,
    limit?: number,
    filter?: ShopQueryFilterDTO,
    order?: ShopQueryOrderDTO
  ) {
    const params = plainToInstance(ShopSearchPaginatedDTO, {
      offset,
      limit,
      term,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<ShopPaginatedOutDTO>(`/shop/search`, {
      params,
    });
    return plainToInstance(ShopPaginatedOutDTO, result);
  }

  async create(
    pointOfSaleName: string,
    establishmentName: string,
    lang: Lang,
    establishmentNumber: string | undefined,
    active: boolean | undefined,
    phones: string[] | undefined,
    emails: string[] | undefined,
    coordinates: CoordinatesOutDTO | undefined,
    address1: string | undefined,
    address2: string | undefined,
    postalCode: number,
    city: string,
    houseNumber: string | undefined,
    openingHours: OpeningHoursOutDTO | undefined,
    linkedShopIds: string[] | undefined,
    activeEnterpriseCBENumber: string | undefined,
    separateRoom: boolean | undefined,
    salesmanId: string | undefined
  ) {
    clearBucketMemoization("ShopService");
    const result = await this.methods.post<ShopOutDTO>(`/shop`, {
      pointOfSaleName,
      establishmentName,
      lang,
      establishmentNumber,
      active,
      phones,
      emails,
      coordinates,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      openingHours,
      linkedShopIds,
      activeEnterpriseCBENumber,
      separateRoom,
      salesmanId,
    });
    return plainToInstance(ShopOutDTO, result);
  }

  async update(
    shopId: string,
    pointOfSaleName: string | undefined,
    establishmentName: string | undefined,
    lang: Lang | undefined,
    establishmentNumber: string | null,
    active: boolean | undefined,
    phones: string[] | undefined,
    emails: string[] | undefined,
    coordinates: CoordinatesOutDTO | undefined,
    address1: string | null,
    address2: string | null,
    postalCode: number | undefined,
    city: string | undefined,
    houseNumber: string | null,
    openingHours: OpeningHoursOutDTO | undefined,
    linkedShopIds: string[] | undefined,
    activeEnterpriseCBENumber: string | undefined | null,
    separateRoom: boolean | undefined,
    salesmanId: string | undefined
  ) {
    clearBucketMemoization("ShopService");
    clearBucketMemoization("ClientService");
    const result = await this.methods.patch<ShopOutDTO>(`/shop/${shopId}`, {
      pointOfSaleName,
      establishmentName,
      lang,
      establishmentNumber,
      active,
      phones,
      emails,
      coordinates,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      openingHours,
      linkedShopIds,
      activeEnterpriseCBENumber,
      separateRoom,
      salesmanId,
    });
    return plainToInstance(ShopOutDTO, result);
  }

  async updateIsOpen(shopId: string, isOpen: boolean) {
    clearBucketMemoization("ShopService");
    const result = await this.methods.patch<ShopOutDTO>(`/shop/${shopId}`, {
      active: isOpen,
    });
    return plainToInstance(ShopOutDTO, result);
  }

  async clearEnterprise(shopId: string) {
    clearBucketMemoization("ShopService");
    await this.methods.patch<ShopOutDTO>(`/shop/${shopId}`, {
      enterpriseCBENumber: null,
    });
  }

  delete(shopId: string) {
    clearBucketMemoization("ShopService");
    return this.methods.delete(`/shop/${shopId}`);
  }

  deleteLink(shopIds: string[]) {
    clearBucketMemoization("ShopService");
    return this.methods.delete(`/shop/link`, {
      data: {
        ids: shopIds,
      },
    });
  }

  linkPersons(shopId: string, persons: PersonsInShopAddDTO) {
    clearBucketMemoization("ShopService");
    return this.methods.post(`/shop/${shopId}/person/link`, persons);
  }

  deleteLinkPerson(shopId: string, personId: string, role?: string) {
    clearBucketMemoization("ShopService");
    return this.methods.delete(
      `/shop/${shopId}/person/link/${personId}${role ? `/${role}` : ""}`
    );
  }

  updateLinkPerson(
    shopId: string,
    personId: string,
    role?: string,
    newRole?: string
  ) {
    clearBucketMemoization("ShopService");
    return this.methods.patch(
      `/shop/${shopId}/person/link/${personId}${role ? `/${role}` : ""}`,
      {
        role: newRole ?? null,
      }
    );
  }

  async export(
    term?: string,
    filter?: ShopQueryFilterDTO,
    order?: ShopQueryOrderDTO,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/shop/export`, {
      params: {
        term: term ? term : undefined,
        filter: filter ? btoa(JSON.stringify(filter)) : undefined,
        order: order ? btoa(JSON.stringify(order)) : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }

  @memoize(1800000, "ShopService")
  async listCoordinates() {
    return plainToInstance(
      ShopCoordinatesOutDTO,
      await this.methods.get<unknown[]>("/shop/coordinates")
    );
  }
}
