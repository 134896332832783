import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { Players1000ListOutDTO } from "../models/players1000/players-1000-list.out.dto";

export class Players1000Service {
  constructor(private methods: HttpMethod) {}

  async export() {
    return await this.methods.getRaw(`/players1000/export`);
  }

  async list() {
    return plainToInstance(
      Players1000ListOutDTO,
      await this.methods.get<unknown[]>("/players1000/list")
    );
  }
}
