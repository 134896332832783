import Vue from "vue";
import Vuex from "vuex";
import {
  NotificationState,
  notificationStore,
} from "@/notification/notification-store";
import { UserState, userStore } from "./modules/user-store";
import { ShopMapState, shopMapStore } from "./modules/shop-map-store";

Vue.use(Vuex);

export type State = {
  user: UserState;
  notification: NotificationState;
  shopMap: ShopMapState;
};

const store = {
  modules: {
    user: userStore,
    notification: notificationStore,
    shopMap: shopMapStore,
  },
};

export default new Vuex.Store<State>(store);
