import { Type } from "class-transformer";
import { ClientType } from "../../client/type/client-type.enum";
import { TicketFieldOutDTO } from "./ticket-field.out.dto";
import { TicketTextI18NOutDTO } from "./ticket-text-i18n.out.dto";

export class TicketDefinitionOutDTO {
  type!: string;
  clientRequired?: boolean;
  onlyForClientTypes?: ClientType[];
  hidden?: boolean;

  @Type(() => TicketTextI18NOutDTO)
  title!: TicketTextI18NOutDTO;

  defaultAssignedEmails?: string[];
  defaultAssignedCategories?: string[];

  image?: string;

  @Type(() => TicketFieldOutDTO)
  fields!: TicketFieldOutDTO[];
}
