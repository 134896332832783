
import { ACLRight } from "@/api/acl-right.enum";
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import CommentActivator from "@/components/comment/CommentActivator.vue";
import { StoreGetter } from "@/store/storeGetter.decorator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    CommentActivator,
  },
})
export default class GlobalCommentActivator extends Vue {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  @Prop({ type: Boolean, default: false })
  public readonly fab!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly noDialog!: boolean;

  @Prop({ type: String, default: "primary" })
  public readonly color!: string;

  @Prop({ type: String, required: true })
  public readonly objectId!: string;

  @Prop({ type: String, required: true })
  public readonly category!: string;

  @Prop({ type: String, required: false })
  public readonly title!: string;

  public ACLRight = ACLRight;

  public fetchFunction = (objectId: string) =>
    this.$api.comment.getThread(objectId);

  public fetchMineFunction = (objectId: string) =>
    this.$api.comment.getMineThread(objectId);

  public createFunction = (
    visibility: CommentVisibility,
    category: string,
    content: string,
    objectId?: string,
    anchor?: string,
    notifiedUser?: string[]
  ) =>
    this.$api.comment.create(
      visibility,
      category,
      content,
      objectId,
      anchor,
      notifiedUser
    );

  public createMePublicFunction = (
    category: string,
    content: string,
    objectId?: string,
    anchor?: string
  ) => this.$api.comment.createMePublic(category, content, objectId, anchor);

  public replyFunction = (
    commentId: string,
    visibility: CommentVisibility,
    content: string,
    notifiedUser?: string[]
  ) => this.$api.comment.reply(commentId, visibility, content, notifiedUser);

  public replyMePublicFunction = (commentId: string, content: string) =>
    this.$api.comment.replyMePublic(commentId, content);

  public deleteFunction = (commentId: string) =>
    this.$api.comment.delete(commentId);

  public deleteMineFunction = (commentId: string) =>
    this.$api.comment.deleteMine(commentId);

  public updateFunction = (commentId: string, visibility: CommentVisibility) =>
    this.$api.comment.update(commentId, visibility);

  public updateMineFunction = (
    commentId: string,
    visibility: CommentVisibility,
    content: string,
    anchor?: string
  ) => this.$api.comment.updateMine(commentId, visibility, content, anchor);
}
