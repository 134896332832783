
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class CommentVisibilityIcon extends Vue {
  @Prop({ type: String, required: true })
  public readonly visibility!: CommentVisibility;

  public CommentVisibility = CommentVisibility;
}
