import { memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { PostalCodeInfoOutDTO } from "../models/postal-code-info.out.dto";

export class PostalCodeService {
  constructor(private methods: HttpMethod) {}

  @memoize(600000, "PostalCodeService")
  async getInfo(postalCode: string) {
    const result = await this.methods.get<PostalCodeInfoOutDTO[]>(
      `/postal-code/${postalCode}`
    );
    return plainToInstance(PostalCodeInfoOutDTO, result);
  }
}
