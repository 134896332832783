import store from "@/store";
import _Vue from "vue";
import { StoredNotification } from "./notification-store";

class Notification {
  private static _instance = new Notification();

  public static install(vue: typeof _Vue) {
    vue.prototype.$notification = Notification._instance;
  }

  public static get instance() {
    return Notification._instance;
  }

  public clear() {
    store.commit("notification/clear");
  }

  public clean() {
    store.commit("notification/clean");
  }

  public success(message: string, dismissible = true, autoCloseDelay = 5000) {
    this.addNotification(message, "success", dismissible, autoCloseDelay);
  }

  public info(message: string, dismissible = true, autoCloseDelay = 5000) {
    this.addNotification(message, "info", dismissible, autoCloseDelay);
  }

  public warning(message: string, dismissible = false, autoCloseDelay = 5000) {
    this.addNotification(message, "warning", dismissible, autoCloseDelay);
  }

  public error(message: string, dismissible = false, autoCloseDelay = 5000) {
    this.addNotification(message, "error", dismissible, autoCloseDelay);
  }

  private addNotification(
    message: string,
    type: "success" | "info" | "warning" | "error",
    dismissible = false,
    autoCloseDelay = 5000
  ) {
    store.dispatch(
      "notification/addNotification",
      new StoredNotification(message, type, dismissible, autoCloseDelay)
    );
  }
}

export default Notification;
export type notificationType = Notification;
