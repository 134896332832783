import { plainToInstance } from "class-transformer";
import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { HttpMethod } from "../http-method.interface";
import { RightOutDTO } from "../models/right.out";
import { RoleOutDTO } from "../models/role.out.dto";

export class ACLService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "ACLService")
  async listRights() {
    const result = await this.methods.get<RightOutDTO[]>("/acl");
    return plainToInstance(RightOutDTO, result);
  }

  @memoize(10000, "ACLService")
  async listRoles() {
    const result = await this.methods.get<RoleOutDTO[]>("/role");
    return plainToInstance(RoleOutDTO, result);
  }

  async addDescriptionToRight(name: string, description: string) {
    clearBucketMemoization("ACLService");
    await this.methods.patch<void>(`acl/${name}`, {
      description,
    });
  }

  async deleteRole(name: string) {
    clearBucketMemoization("ACLService");
    await this.methods.delete<void>(`role/${name}`);
  }

  async setRoleRights(name: string, rights: string[]) {
    clearBucketMemoization("ACLService");
    await this.methods.put<void>(`role`, {
      name,
      rights,
    });
  }
}
