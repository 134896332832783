
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import SMSFields from "./SMSFields.vue";
import { SMSFieldsData } from "@/api/models/sms/sms-fields-data";
import { plainToInstance } from "class-transformer";
import { VForm } from "@/utils/VForm.type";
import { ReceiverItem } from "@/api/models/sms/type/receiver-item.interface";

@Component({
  components: {
    SMSFields,
  },
})
export default class SMSActivator extends Vue {
  @Prop({ type: Function, required: true })
  public readonly sendSMSFunction!: (
    receivers: string[],
    message: string
  ) => Promise<void>;

  @Prop({ type: Boolean, default: false })
  public readonly fab!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly noDialog!: boolean;

  @Prop({ type: String, default: "primary" })
  public readonly color!: string;

  @Prop({ type: String, default: "en" })
  public readonly lang!: string;

  @Prop({ type: Array, required: false })
  public readonly receiverItems?: ReceiverItem[];

  public loading = false;
  public success = false;
  public SMSFormDialog = false;
  public SMSFieldsData: SMSFieldsData | null = null;

  public openSMSFormDialog() {
    this.SMSFieldsData = plainToInstance(SMSFieldsData, {
      receivers: [...(this.receiverItems ?? [])],
      message: "",
      signature: this.$tc("sms.defaultSignature", 0, this.lang),
    });
    this.SMSFormDialog = true;
  }

  public async sendSMS() {
    if (this.SMSFieldsData === null) {
      return;
    }

    this.loading = true;

    const form = this.$refs.form as VForm;
    if (form?.validate()) {
      const receivers = this.SMSFieldsData.receivers.map((receiver) =>
        typeof receiver === "string" ? receiver : receiver.value
      );
      try {
        await this.sendSMSFunction(
          receivers,
          `${this.SMSFieldsData.message} - ${this.SMSFieldsData.signature}`
        );
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.SMSFormDialog = false;
        }, 2000);
      } catch (err) {
        //
      }
    } else {
      const input = form.inputs.find((input) => !input.valid);
      if (
        input &&
        typeof input.focus === "function" &&
        input.$el !== undefined
      ) {
        this.$vuetify.goTo(input.$el, { offset: 50 });
        input.focus();
      }
    }

    this.loading = false;
  }
}
