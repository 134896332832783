import { HttpMethod } from "../http-method.interface";
import { EnterpriseOutDTO } from "../models/enterprise/enterprise.out.dto";
import { EnterpriseFieldsData } from "../models/enterprise/form/enterprise-fields-data";
import { PersonInEnterpriseOutDTO } from "../models/enterprise/person-in-enterprise-out.dto";
import { PersonFieldsDataWithRole } from "../models/person/form/person-fields-data-with-role";
import { EnterpriseService } from "../services/enterprise.service";
import { ShopService } from "../services/shop.service";
import { PersonUtilService } from "./person-util.service";

export class EnterpriseUtilService {
  constructor(private methods: HttpMethod) {}

  async updateWithPersons(
    enterprise: EnterpriseFieldsData,
    personsToCreate?: PersonFieldsDataWithRole[],
    personsToLink?: PersonInEnterpriseOutDTO[],
    personsToUpdate?: PersonInEnterpriseOutDTO[]
  ) {
    const enterpriseService = new EnterpriseService(this.methods);

    const updatedEnterprise = await enterpriseService.update(
      enterprise.cbeNumber,
      enterprise.name,
      enterprise.address1 || undefined,
      enterprise.address2 || undefined,
      enterprise.postalCode
        ? Number.parseInt(enterprise.postalCode)
        : undefined,
      enterprise.city || undefined,
      enterprise.houseNumber || undefined,
      enterprise.juridicalForm || undefined,
      enterprise.juridicalSituation || undefined,
      enterprise.bankAccountNumber || undefined,
      enterprise.type || undefined
    );

    await this.linkAndCreatePersons(
      updatedEnterprise.cbeNumber,
      personsToCreate,
      personsToLink
    );

    // Update persons
    if (personsToUpdate) {
      await Promise.all(
        personsToUpdate.map((personToUpdate) => {
          const personInEnterprise = updatedEnterprise.persons?.find(
            (person) => person.person.id === personToUpdate.person.id
          );
          if (personInEnterprise) {
            return enterpriseService.updateLinkPerson(
              enterprise.cbeNumber,
              personToUpdate.person.id,
              personInEnterprise?.role,
              personToUpdate.role
            );
          } else {
            return Promise.resolve();
          }
        })
      );
    }

    return updatedEnterprise;
  }

  async createWithPersons(
    enterprise: EnterpriseFieldsData,
    personsToCreate?: PersonFieldsDataWithRole[],
    personsToLink?: PersonInEnterpriseOutDTO[]
  ) {
    const enterpriseService = new EnterpriseService(this.methods);

    const createdEnterprise = await enterpriseService.create(
      enterprise.cbeNumber,
      enterprise.type || undefined,
      enterprise.name,
      enterprise.address1 || undefined,
      enterprise.address2 || undefined,
      enterprise.postalCode
        ? Number.parseInt(enterprise.postalCode)
        : undefined,
      enterprise.city || undefined,
      enterprise.houseNumber || undefined,
      enterprise.juridicalForm || undefined,
      enterprise.juridicalSituation || undefined,
      enterprise.bankAccountNumber || undefined
    );

    await this.linkAndCreatePersons(
      createdEnterprise.cbeNumber,
      personsToCreate,
      personsToLink
    );

    return createdEnterprise;
  }

  async linkAndCreatePersons(
    enterpriseCbeNumber: string,
    personsToCreate?: PersonFieldsDataWithRole[],
    personsToLink?: PersonInEnterpriseOutDTO[]
  ) {
    const enterpriseService = new EnterpriseService(this.methods);
    const personUtilService = new PersonUtilService(this.methods);

    let toLink = personsToLink
      ? personsToLink.map((personInEnterprise) => ({
          role: personInEnterprise.role,
          personId: personInEnterprise.person.id,
        }))
      : [];

    if (personsToCreate) {
      const createdPersons = await personUtilService.createPersons(
        personsToCreate
      );
      toLink = [...toLink, ...createdPersons];
    }

    if (toLink.length > 0) {
      await enterpriseService.linkPersons(enterpriseCbeNumber, {
        persons: toLink,
      });
    }
  }

  async unlinkAndDelete(enterprise: EnterpriseOutDTO) {
    const enterpriseService = new EnterpriseService(this.methods);
    const shopService = new ShopService(this.methods);

    // Unlink persons
    if (enterprise.persons) {
      await Promise.all(
        enterprise.persons.map((person) =>
          enterpriseService.deleteLinkPerson(
            enterprise.cbeNumber,
            person.person.id,
            person.role
          )
        )
      );
    }

    // Unlink shops
    if (enterprise.shops) {
      await Promise.all(
        enterprise.shops.map((shop) => shopService.clearEnterprise(shop.id))
      );
    }

    await enterpriseService.delete(enterprise.cbeNumber);
  }
}
