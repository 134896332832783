
import { ACLRight } from "@/api/acl-right.enum";
import { CommentOutDTO } from "@/api/models/comment/comment.out.dto";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import { StoreGetter } from "@/store/storeGetter.decorator";
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import ConfirmationPrompt from "../ConfirmationPrompt.vue";
import CommentVisibilityIcon from "./CommentVisibilityIcon.vue";
import CommentVisibilitySelector from "./CommentVisibilitySelector.vue";

@Component({
  components: {
    CommentVisibilityIcon,
    ConfirmationPrompt,
    CommentVisibilitySelector,
  },
})
export default class CommentThread extends Vue {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  @Prop({ type: Array, default: () => [] })
  public readonly thread!: CommentOutDTO[];

  @Prop({ type: Boolean, default: true })
  public readonly canUpdateVisibility!: boolean;

  @Emit("reply")
  emitReply(commentId: string, commentVisibility: CommentVisibility) {
    return { commentId, commentVisibility };
  }

  @Emit("delete")
  emitDelete() {
    return this.commentToDelete?.id;
  }

  @Emit("deleteMine")
  emitDeleteMine() {
    return this.commentToDelete?.id;
  }

  @Emit("update")
  emitUpdate(comment: CommentOutDTO) {
    return comment;
  }

  @Emit("updateMine")
  emitUpdateMine(comment: CommentOutDTO) {
    return comment;
  }

  public ACLRight = ACLRight;
  public confirmDeleteDialog = false;
  public commentToDelete: CommentOutDTO | null = null;
  public commentToEdit: CommentOutDTO | null = null;

  public get sortedThread() {
    return this.thread.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );
  }

  public openDeleteDialog(comment: CommentOutDTO) {
    this.commentToDelete = comment;
    this.confirmDeleteDialog = true;
  }

  public closeDeleteDialog() {
    this.commentToDelete = null;
    this.confirmDeleteDialog = false;
  }

  public confirmDelete() {
    if (this.user.hasRight(ACLRight.COMMENT_DELETE)) {
      this.emitDelete();
    } else if (this.commentToDelete?.creator.email === this.user.email) {
      this.emitDeleteMine();
    }
    this.closeDeleteDialog();
  }

  public editComment(comment: CommentOutDTO) {
    this.commentToEdit = { ...comment };
  }

  public saveCommentToEdit() {
    if (this.commentToEdit) {
      if (this.commentToEdit.creator.email === this.user.email) {
        this.emitUpdateMine(this.commentToEdit);
      } else if (this.user.hasRight(ACLRight.COMMENT_UPDATE_VISIBILITY)) {
        this.emitUpdate(this.commentToEdit);
      }
      this.cancelEdit();
    }
  }

  public cancelEdit() {
    this.commentToEdit = null;
  }
}
