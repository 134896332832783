
import { Lang } from "@/utils/lang.enum";
import { mapEnumToItem } from "@/utils/map-enum-to-items.function";
import Vue from "vue";
import Component from "vue-class-component";
import LanguageIcon from "@/components/common/icons/LanguageIcon.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    LanguageIcon,
  },
})
export default class LangMenu extends Vue {
  @Prop({ type: Boolean, default: false })
  public showSetDefaultLang!: boolean;

  public showMenu = false;

  public readonly langItems = mapEnumToItem(Lang, "language");

  changeLang(lang: string) {
    this.$i18n.locale = lang;
    this.$store.commit("user/setLang", lang);
  }
}
