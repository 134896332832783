var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.sortedThread),function(comment){return [_c('v-list-item',{key:comment.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-comment ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"thread-box rounded-lg rounded-b-0 pt-4 px-4 ma-0",class:{
              'font-weight-bold': comment.creator.email === _vm.user.email,
            }},[(comment.creator.email === _vm.user.email)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("common.label.me"))+" ("),(comment.creator.displayedName)?_c('span',{attrs:{"title":comment.creator.email}},[_vm._v(" "+_vm._s(comment.creator.displayedName)+" ")]):_c('span',[_vm._v(_vm._s(comment.creator.email))]),_vm._v(") ")]):(comment.creator.displayedName)?_c('span',{attrs:{"title":comment.creator.email}},[_vm._v(" "+_vm._s(comment.creator.displayedName)+" ")]):_c('span',[_vm._v(" "+_vm._s(comment.creator.email)+" ")])]),_c('v-list-item-title',{staticClass:"thread-box rounded-lg rounded-t-0 py-4 px-4"},[(
                _vm.commentToEdit !== null &&
                _vm.commentToEdit.id === comment.id &&
                comment.creator.email === _vm.user.email
              )?[_c('v-textarea',{attrs:{"rows":"3"},model:{value:(_vm.commentToEdit.content),callback:function ($$v) {_vm.$set(_vm.commentToEdit, "content", $$v)},expression:"commentToEdit.content"}})]:[_c('p',{staticClass:"text-content"},[_vm._v(_vm._s(comment.content))])]],2),_c('v-list-item-subtitle',[(
                _vm.commentToEdit !== null &&
                _vm.commentToEdit.id === comment.id &&
                (comment.creator.email === _vm.user.email ||
                  _vm.user.hasRight(_vm.ACLRight.COMMENT_UPDATE_VISIBILITY))
              )?[(_vm.canUpdateVisibility)?_c('CommentVisibilitySelector',{model:{value:(_vm.commentToEdit.visibility),callback:function ($$v) {_vm.$set(_vm.commentToEdit, "visibility", $$v)},expression:"commentToEdit.visibility"}}):_vm._e(),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":_vm.cancelEdit}},[_vm._v(" "+_vm._s(_vm.$t("common.action.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","small":"","color":"success"},on:{"click":_vm.saveCommentToEdit}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t("common.action.save"))+" ")],1)]:[(_vm.canUpdateVisibility)?_c('CommentVisibilityIcon',{attrs:{"visibility":comment.visibility}}):_vm._e(),(
                  _vm.user.hasRight(_vm.ACLRight.COMMENT_UPDATE_VISIBILITY) ||
                  _vm.user.email === comment.creator.email
                )?_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editComment(comment)}}},[_vm._v(" "+_vm._s(_vm.$t("common.action.edit"))+" ")]):_vm._e(),(
                  _vm.user.hasRight(_vm.ACLRight.COMMENT_DELETE) ||
                  _vm.user.email === comment.creator.email
                )?_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openDeleteDialog(comment)}}},[_vm._v(" "+_vm._s(_vm.$t("common.action.delete"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.emitReply(comment.id, comment.visibility)}}},[_vm._v(" "+_vm._s(_vm.$t("comment.action.reply"))+" ")]),_vm._v(" "+_vm._s(comment.createdAt.toLocaleDateString(_vm.$i18n.locale))+" "+_vm._s(comment.createdAt.toLocaleTimeString())+" ")]],2)],1)],1)]})],2),_c('v-dialog',{attrs:{"persistent":"","width":"auto"},model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c('ConfirmationPrompt',{attrs:{"title":_vm.$t('common.prompt.confirmDelete'),"confirmLabel":_vm.$t('common.action.delete')},on:{"cancel":_vm.closeDeleteDialog,"confirm":_vm.confirmDelete}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }