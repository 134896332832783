import { Type } from "class-transformer";

export class SMSHistoryOutDTO {
  @Type(() => Date)
  createdAt!: Date;

  message!: string;
  receivers?: string[];
  invalidReceivers?: string[];
  totalCreditsRemoved!: number;
}
