import { HoursInterval } from "./opening-hours.interface";

export class OpeningHoursOutDTO {
  mon?: HoursInterval[];
  tue?: HoursInterval[];
  wed?: HoursInterval[];
  thu?: HoursInterval[];
  fri?: HoursInterval[];
  sat?: HoursInterval[];
  sun?: HoursInterval[];
}
