import { ACLRight } from "../acl-right.enum";
import { HttpMethod } from "../http-method.interface";
import { CallCenterTicketData } from "../models/call-center/call-center-ticket-data";
import { TicketDefinitionOutDTO } from "../models/call-center/ticket-definition/ticket-definition.out.dto";
import { TicketFieldType } from "../models/call-center/type/ticket-field-type.enum";
import { TicketStatus } from "../models/call-center/type/ticket-status.enum";
import { UserWithRightOutDTO } from "../models/user/user-with-right.out";
import {
  CallCenterService,
  TicketFileIntegrityError,
} from "../services/call-center.service";

export class CallCenterUtilService {
  constructor(private methods: HttpMethod) {}

  async createTicketWithFiles(
    ticketDefinition: TicketDefinitionOutDTO,
    user: UserWithRightOutDTO,
    clientId?: string,
    description?: string,
    data?: CallCenterTicketData
  ) {
    const callCenterService = new CallCenterService(this.methods);
    const dataWithoutFiles: CallCenterTicketData | undefined = data
      ? { ...data }
      : undefined;

    // Detect file field
    const fileFieldNames = ticketDefinition.fields
      .filter((field) => field.type === TicketFieldType.File)
      .map((field) => field.name);

    if (dataWithoutFiles !== undefined && fileFieldNames.length > 0) {
      // Reset data containing files to create ticket before upload
      for (const fileFieldName of fileFieldNames) {
        if (dataWithoutFiles[fileFieldName]) {
          dataWithoutFiles[fileFieldName] = undefined;
        }
      }
    }

    let ticket;
    if (user.hasRight(ACLRight.CALL_CENTER_CREATE_TICKET)) {
      ticket = await callCenterService.createTicket(
        ticketDefinition.type,
        clientId,
        description,
        dataWithoutFiles
      );
    } else {
      ticket = await callCenterService.createMeTicket(
        ticketDefinition.type,
        clientId,
        description,
        dataWithoutFiles
      );
    }

    if (data !== undefined && fileFieldNames.length > 0) {
      const files = Object.entries(data).reduce(
        (acc, [fieldName, fieldValue]) => {
          if (fileFieldNames.includes(fieldName)) {
            acc[fieldName] = fieldValue as File;
          }
          return acc;
        },
        {} as Record<string, File>
      );

      await this.uploadTicketFiles(ticket.id, fileFieldNames, files, user);

      // Check upload
      const ticketFileIntegrity =
        await callCenterService.getTicketFileIntegrity(ticket.id, 1);

      // Upload failed, retry
      if (!ticketFileIntegrity.valid) {
        const missingFiles = Object.entries(data).reduce(
          (acc, [fieldName, fieldValue]) => {
            if (ticketFileIntegrity.missingFieldNames.includes(fieldName)) {
              acc[fieldName] = fieldValue as File;
            }
            return acc;
          },
          {} as Record<string, File>
        );

        // Retry files upload (missing only)
        await this.uploadTicketFiles(
          ticket.id,
          ticketFileIntegrity.missingFieldNames,
          missingFiles,
          user
        );

        // Check upload again
        const ticketFileIntegrityAgain =
          await callCenterService.getTicketFileIntegrity(ticket.id, 2);

        // Still invalid => error
        if (!ticketFileIntegrityAgain.valid) {
          throw new TicketFileIntegrityError(
            ticket.id,
            ticketFileIntegrityAgain
          );
        }
      }
    }

    return ticket;
  }

  async uploadTicketFiles(
    ticketId: string,
    fileFieldNames: string[],
    files: Record<string, File>,
    user: UserWithRightOutDTO
  ) {
    const callCenterService = new CallCenterService(this.methods);

    for (const fileFieldName of fileFieldNames) {
      if (files[fileFieldName]) {
        if (user.hasRight(ACLRight.CALL_CENTER_STORE_TICKET_FILE)) {
          await callCenterService.storeTicketFile(
            ticketId,
            files[fileFieldName] as File,
            fileFieldName
          );
        } else {
          await callCenterService.storeMyTicketFile(
            ticketId,
            files[fileFieldName] as File,
            fileFieldName
          );
        }
      }
    }
  }

  async updateTicketWithFile(
    ticketDefinition: TicketDefinitionOutDTO,
    ticketId: string,
    assignedUserEmails?: string[],
    assignedCategories?: string[],
    description?: string,
    data?: CallCenterTicketData,
    status?: TicketStatus
  ) {
    const callCenterService = new CallCenterService(this.methods);

    // Detect file field
    const fileFieldNames = ticketDefinition.fields
      .filter((field) => field.type === TicketFieldType.File)
      .map((field) => field.name);

    if (data) {
      for (const fileFieldName of fileFieldNames) {
        if (data[fileFieldName] && typeof data[fileFieldName] !== "string") {
          const uploadedFile = await callCenterService.storeTicketFile(
            ticketId,
            data[fileFieldName] as File,
            fileFieldName
          );
          data[fileFieldName] = JSON.stringify(uploadedFile);
        }
      }
    }

    return await callCenterService.updateTicket(
      ticketId,
      assignedUserEmails,
      assignedCategories,
      description,
      data,
      status,
      ticketDefinition.type
    );
  }
}
