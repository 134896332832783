
import { ACLRight } from "@/api/acl-right.enum";
import { CommentThreadOutDTO } from "@/api/models/comment/comment-thread.out.dto";
import { CommentOutDTO } from "@/api/models/comment/comment.out.dto";
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import { StoreGetter } from "@/store/storeGetter.decorator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CommentCard from "./CommentCard.vue";

@Component({
  components: {
    CommentCard,
  },
})
export default class CommentActivator extends Vue {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  @Prop({ type: Function, required: true })
  public readonly fetchFunction!: (
    commentId: string
  ) => Promise<CommentThreadOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly fetchMineFunction!: (
    commentId: string
  ) => Promise<CommentThreadOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly createFunction!: (
    visibility: CommentVisibility,
    category: string,
    content: string,
    objectId?: string,
    anchor?: string,
    notifiedUser?: string[]
  ) => Promise<CommentOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly createMePublicFunction!: (
    category: string,
    content: string,
    objectId?: string,
    anchor?: string
  ) => Promise<CommentOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly replyFunction!: (
    commentId: string,
    visibility: CommentVisibility,
    content: string,
    notifiedUser?: string[]
  ) => Promise<CommentOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly replyMePublicFunction!: (
    commentId: string,
    content: string
  ) => Promise<CommentOutDTO>;

  @Prop({ type: Function, required: true })
  public readonly deleteFunction!: (commentId: string) => Promise<void>;

  @Prop({ type: Function, required: true })
  public readonly deleteMineFunction!: (commentId: string) => Promise<void>;

  @Prop({ type: Function, required: true })
  public readonly updateMineFunction!: (
    commentId: string,
    visibility: CommentVisibility,
    content: string,
    anchor?: string
  ) => Promise<void>;

  @Prop({ type: Function, required: true })
  public readonly updateFunction!: (
    commentId: string,
    visibility: CommentVisibility
  ) => Promise<void>;

  @Prop({ type: Boolean, default: false })
  public readonly fab!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly noDialog!: boolean;

  @Prop({ type: String, default: "primary" })
  public readonly color!: string;

  @Prop({ type: String, required: true })
  public readonly objectId!: string;

  @Prop({ type: String, required: true })
  public readonly category!: string;

  @Prop({ type: String, required: false })
  public readonly title!: string;

  @Prop({ type: Number, default: 5 })
  public readonly nbPerPage!: number;

  @Prop({ type: Boolean, default: true })
  public readonly canUpdateVisibility!: boolean;

  @Watch("objectId", { immediate: true })
  onObjectIdChange() {
    this.loadThread();
  }

  public threads: CommentOutDTO[][] | null = null;
  public commentDialog = false;
  public loading = false;
  public ACLRight = ACLRight;

  public replyToThreadIndex: number | null = null;
  public replyToCommentId: string | null = null;
  public replyContent = "";
  public replyVisibility: CommentVisibility = CommentVisibility.Restricted;

  public newCommentContent = "";
  public newCommentVisibility: CommentVisibility = CommentVisibility.Restricted;

  public get countComments() {
    if (this.threads !== null) {
      return this.threads.reduce((acc, thread) => {
        acc += thread.length;
        return acc;
      }, 0);
    }
    return 0;
  }

  public async openThread() {
    this.commentDialog = true;
  }

  public async loadThread() {
    this.loading = true;
    try {
      let result;
      if (this.user.hasRight(ACLRight.COMMENT_GET)) {
        result = await this.fetchFunction(this.objectId);
      } else {
        result = await this.fetchMineFunction(this.objectId);
      }

      this.threads = result.threads;
    } catch (err) {
      //
    }
    this.loading = false;
  }

  public enableReplyTo(
    index: number,
    commentId: string,
    commentVisibility: CommentVisibility
  ) {
    this.replyToThreadIndex = index;
    this.replyToCommentId = commentId;
    this.replyVisibility = commentVisibility;
  }

  public async createComment() {
    try {
      if (this.user.hasRight(ACLRight.COMMENT_CREATE)) {
        await this.createFunction(
          this.newCommentVisibility,
          this.category,
          this.newCommentContent,
          this.objectId
        );
      } else {
        await this.createMePublicFunction(
          this.category,
          this.newCommentContent,
          this.objectId
        );
      }

      await this.loadThread();
      this.newCommentContent = "";
      this.newCommentVisibility = CommentVisibility.Restricted;
    } catch (err) {
      //
    }
  }

  public async reply() {
    if (this.replyToCommentId === null) {
      return;
    }

    try {
      if (this.user.hasRight(ACLRight.COMMENT_REPLY)) {
        await this.replyFunction(
          this.replyToCommentId,
          this.replyVisibility,
          this.replyContent
        );
      } else {
        await this.replyMePublicFunction(
          this.replyToCommentId,
          this.replyContent
        );
      }
      await this.loadThread();
      this.replyContent = "";
      this.replyVisibility = CommentVisibility.Public;
      this.replyToCommentId = null;
      this.replyToThreadIndex = null;
    } catch (err) {
      //
    }
  }

  public async deleteThread(commentId: string) {
    try {
      await this.deleteFunction(commentId);
      await this.loadThread();
    } catch (err) {
      //
    }
  }

  public async deleteMineThread(commentId: string) {
    try {
      await this.deleteMineFunction(commentId);
      await this.loadThread();
    } catch (err) {
      //
    }
  }

  public async updateComment(comment: CommentOutDTO) {
    try {
      await this.updateFunction(comment.id, comment.visibility);
      this.loadThread();
    } catch (err) {
      //
    }
  }

  public async updateMineComment(comment: CommentOutDTO) {
    try {
      await this.updateMineFunction(
        comment.id,
        comment.visibility,
        comment.content,
        comment.anchor
      );
      this.loadThread();
    } catch (err) {
      //
    }
  }
}
