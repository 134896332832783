import { Type } from "class-transformer";
import {
  SfkFlowSbtech,
  SfkFlowCashCard,
  SfkFlowTerminal,
  SfkFlowMybet,
  SfkFlowWebsite,
} from "./sfk-api-data.dto";
import { SfkErrorReason } from "./sfk-error-reason.enum";
import { ExternalFinancialDataType } from "./external-financial-data-type.enum";

export class SfkDataErroredDTO {
  type!: ExternalFinancialDataType;
  reason!: SfkErrorReason;
  flow!:
    | SfkFlowSbtech
    | SfkFlowCashCard
    | SfkFlowTerminal
    | SfkFlowMybet
    | SfkFlowWebsite;
}

export class SfkDataContentErroredDTO {
  @Type(() => Date)
  date!: Date;

  @Type(() => SfkDataErroredDTO)
  data!: SfkDataErroredDTO[];
}

export class SfkErroredOutDTO {
  @Type(() => SfkDataContentErroredDTO)
  erroredByDate!: SfkDataContentErroredDTO[];
}
