import { Type } from "class-transformer";
import { EnterpriseSummaryOutDTO } from "../enterprise/enterprise-summary.out.dto";
import { ShopSummaryOutDTO } from "../shop/shop-summary.out.dto";

export class PersonOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  firstName!: string;
  lastName!: string;

  address1?: string;
  address2?: string;
  postalCode?: number;
  city?: string;
  houseNumber?: string;
  country?: string;

  bankAccountNumber?: string;
  phones?: string[];
  emails?: string[];
  stateRegisterNumber?: string;
  nationality?: string;

  @Type(() => Date)
  birthDate?: Date;

  birthLocation?: string;

  @Type(() => EnterpriseSummaryOutDTO)
  enterprises?: EnterpriseSummaryOutDTO[];

  @Type(() => ShopSummaryOutDTO)
  shops?: ShopSummaryOutDTO[];
}
