import { Type } from "class-transformer";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { UserOutDTO } from "../user/user.out";
import { ColorFromPriority, ColorFromStatus } from "./call-center-ticket-color";
import { TicketStatus } from "./type/ticket-status.enum";

export class CallCenterTicketSummaryListOutDTO {
  id!: string;
  ref!: number;

  @Type(() => Date)
  changedAt!: Date;

  objectId?: string;
  type!: string;

  @Type(() => UserOutDTO)
  assignedUsers?: UserOutDTO[];

  assignedCategories?: string[];
  status!: TicketStatus;
  priority!: number;

  @Type(() => ClientSummaryOutDTO)
  client?: ClientSummaryOutDTO;

  public get color() {
    return ColorFromStatus[this.status];
  }

  public get priorityColor() {
    if (this.priority > 0) {
      return ColorFromPriority[Math.min(2, this.priority) as 1 | 2];
    }
    return "";
  }
}
