import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { TicketStatus } from "../models/call-center/type/ticket-status.enum";
import { InterventionDataDTO } from "../models/installation/intervention-data.dto";
import { InterventionPaginatedOutDTO } from "../models/installation/intervention-paginated.out.dto";
import { InterventionQueryFilterDTO } from "../models/installation/intervention-query-filter.dto";
import { InterventionQueryOrderDTO } from "../models/installation/intervention-query-order.dto";
import { InterventionOutDTO } from "../models/installation/intervention.out.dto";
import { ActionInventory } from "../models/installation/type/action-inventory.enum";
import { DocumentAction } from "../models/document/type/document-action.enum";
import { InterventionFileUploadOutDTO } from "../models/installation/intervention-file-upload.out.dto";

export class InterventionService {
  constructor(private methods: HttpMethod) {}

  async createIntervention(
    clientId: string,
    assignedUserEmails?: string[],
    assignedCategories?: string[],
    description?: string,
    data?: InterventionDataDTO
  ) {
    this.cleanInterventionData(data);
    clearBucketMemoization("InterventionService");
    const result = await this.methods.post<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention`,
      {
        assignedUserEmails,
        assignedCategories,
        description,
        data,
      }
    );
    return plainToInstance(InterventionOutDTO, result);
  }

  private removeBase64Preview(data?: InterventionDataDTO) {
    if (data === undefined) {
      return undefined;
    }
    return {
      ...data,
      files: data?.files?.map((f) => ({ ...f, base64Preview: undefined })),
      deliveryReceipt: data?.deliveryReceipt
        ? { ...data.deliveryReceipt, base64Preview: undefined }
        : undefined,
    };
  }

  async updateIntervention(
    clientId: string,
    interventionId: string,
    assignedUserEmails?: string[],
    assignedCategories?: string[],
    description?: string,
    data?: InterventionDataDTO,
    status?: TicketStatus,
    priority?: number
  ) {
    this.cleanInterventionData(data);
    clearBucketMemoization("InterventionService");
    const result = await this.methods.patch<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/${interventionId}`,
      {
        assignedUserEmails,
        assignedCategories,
        description,
        data: this.removeBase64Preview(data),
        status,
        priority,
      }
    );
    return plainToInstance(InterventionOutDTO, result);
  }

  async updateMyIntervention(
    clientId: string,
    interventionId: string,
    assignedUserEmails?: string[],
    assignedCategories?: string[],
    description?: string,
    data?: InterventionDataDTO,
    status?: TicketStatus,
    priority?: number
  ) {
    this.cleanInterventionData(data);
    clearBucketMemoization("InterventionService");
    const result = await this.methods.patch<InterventionOutDTO>(
      `/client/${clientId}/installation/me/intervention/${interventionId}`,
      {
        assignedUserEmails,
        assignedCategories,
        description,
        data: this.removeBase64Preview(data),
        status,
        priority,
      }
    );
    return plainToInstance(InterventionOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async getIntervention(
    clientId: string,
    interventionId: string,
    includeHistory = true
  ) {
    const result = await this.methods.get<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/${interventionId}`,
      {
        params: {
          includeHistory,
        },
      }
    );

    if (result.data) {
      if (result.data.installationDate) {
        result.data.installationDate = new Date(result.data.installationDate);
      }
      if (result.data.inventory) {
        result.data.inventory = result.data.inventory.map((i) => {
          if (
            i.action === ActionInventory.creation ||
            i.action === ActionInventory.update
          ) {
            if (i.installDate) {
              i.installDate = new Date(i.installDate);
            }
            if (i.uninstallDate) {
              i.uninstallDate = new Date(i.uninstallDate);
            }
          }
          return i;
        });
      }
    }

    return plainToInstance(InterventionOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async getMyIntervention(
    clientId: string,
    interventionId: string,
    includeHistory = true
  ) {
    const result = await this.methods.get<InterventionOutDTO>(
      `/client/${clientId}/installation/me/intervention/${interventionId}`,
      {
        params: {
          includeHistory,
        },
      }
    );

    if (result.data) {
      if (result.data.installationDate) {
        result.data.installationDate = new Date(result.data.installationDate);
      }
      if (result.data.inventory) {
        result.data.inventory = result.data.inventory.map((i) => {
          if (
            i.action === ActionInventory.creation ||
            i.action === ActionInventory.update
          ) {
            if (i.installDate) {
              i.installDate = new Date(i.installDate);
            }
            if (i.uninstallDate) {
              i.uninstallDate = new Date(i.uninstallDate);
            }
          }
          return i;
        });
      }
    }

    return plainToInstance(InterventionOutDTO, result);
  }

  async deleteIntervention(clientId: string, interventionId: string) {
    clearBucketMemoization("InterventionService");
    await this.methods.delete(
      `/client/${clientId}/installation/intervention/${interventionId}`
    );
  }

  async storeInterventionFile(
    clientId: string,
    interventionId: string,
    file: File
  ) {
    clearBucketMemoization("InterventionService");
    return await this.methods.postFile(
      `/client/${clientId}/installation/intervention/${interventionId}/file`,
      {
        file,
      }
    );
  }

  @memoize(10000, "InterventionService")
  async getInterventionFile(
    clientId: string,
    interventionId: string,
    fileName: string
  ) {
    return await this.methods.getRaw(
      `/client/${clientId}/installation/intervention/${interventionId}/file/${fileName}`
    );
  }

  async updateInterventionFile(
    clientId: string,
    interventionId: string,
    fileName: string,
    action: DocumentAction
  ) {
    clearBucketMemoization("InterventionService");
    const result = await this.methods.patch<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/${interventionId}/file/${fileName}`,
      { action }
    );
    return plainToInstance(InterventionFileUploadOutDTO, result);
  }

  async applyInterventionChanges(clientId: string, interventionId: string) {
    clearBucketMemoization("InterventionService");
    const result = await this.methods.patch<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/${interventionId}/apply-changes`
    );
    return plainToInstance(InterventionOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async listIntervention(
    offset?: number,
    limit?: number,
    filter?: InterventionQueryFilterDTO,
    order?: InterventionQueryOrderDTO
  ) {
    const params = plainToInstance(InterventionPaginatedOutDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<InterventionPaginatedOutDTO>(
      `/installation/intervention`,
      {
        params,
      }
    );
    return plainToInstance(InterventionPaginatedOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async listMyIntervention(
    offset?: number,
    limit?: number,
    filter?: InterventionQueryFilterDTO,
    order?: InterventionQueryOrderDTO
  ) {
    const params = plainToInstance(InterventionPaginatedOutDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
    });
    const result = await this.methods.get<InterventionPaginatedOutDTO>(
      `/installation/me/intervention`,
      {
        params,
      }
    );
    return plainToInstance(InterventionPaginatedOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async searchIntervention(
    term: string,
    offset?: number,
    limit?: number,
    filter?: InterventionQueryFilterDTO,
    order?: InterventionQueryOrderDTO
  ) {
    const params = plainToInstance(InterventionPaginatedOutDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      term,
    });
    const result = await this.methods.get<InterventionPaginatedOutDTO>(
      `/installation/intervention-search`,
      {
        params,
      }
    );
    return plainToInstance(InterventionPaginatedOutDTO, result);
  }

  @memoize(10000, "InterventionService")
  async searchMyIntervention(
    term: string,
    offset?: number,
    limit?: number,
    filter?: InterventionQueryFilterDTO,
    order?: InterventionQueryOrderDTO
  ) {
    const params = plainToInstance(InterventionPaginatedOutDTO, {
      offset,
      limit,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      term,
    });
    const result = await this.methods.get<InterventionPaginatedOutDTO>(
      `/installation/me/intervention-search`,
      {
        params,
      }
    );
    return plainToInstance(InterventionPaginatedOutDTO, result);
  }

  async generateDeliveryReceipt(
    clientId: string,
    interventionId: string,
    receiptFirstName: string,
    receiptLastName: string,
    receiptEmail: string,
    signatureB64: string,
    forceGeneration?: boolean
  ) {
    clearBucketMemoization("InterventionService");
    const result = await this.methods.post<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/${interventionId}/generate-delivery-receipt`,
      {
        receiptFirstName,
        receiptLastName,
        receiptEmail,
        signatureB64,
        forceGeneration,
      }
    );
    return plainToInstance(InterventionOutDTO, result);
  }

  async deleteDeliveryReceipt(clientId: string, interventionId: string) {
    clearBucketMemoization("InterventionService");
    return plainToInstance(
      InterventionOutDTO,
      await this.methods.delete(
        `/client/${clientId}/installation/intervention/${interventionId}/delete-delivery-receipt`
      )
    );
  }

  @memoize(10000, "InterventionService")
  async getInterventionFromCallCenterTicketId(
    clientId: string,
    callCenterTicketId: string
  ) {
    const result = await this.methods.get<InterventionOutDTO>(
      `/client/${clientId}/installation/intervention/from-call-center/${callCenterTicketId}`
    );
    if (result) {
      return plainToInstance(InterventionOutDTO, result);
    }
    return undefined;
  }

  private cleanInterventionData(
    data: InterventionDataDTO | undefined
  ): InterventionDataDTO | undefined {
    if (data && data.inventory) {
      for (const [key, i] of data.inventory.entries()) {
        if (i.action === ActionInventory.creation) {
          data.inventory[key] = {
            action: i.action,
            type: i.type,
            quantity: i.quantity,
            model: i.model,
            serialNumber: i.serialNumber,
            installDate: i.installDate,
            uninstallDate: i.uninstallDate,
            extraInfo: i.extraInfo,
          };
        } else if (i.action === ActionInventory.update) {
          data.inventory[key] = {
            action: i.action,
            id: i.id,
            quantity: i.quantity,
            model: i.model,
            serialNumber: i.serialNumber,
            installDate: i.installDate,
            uninstallDate: i.uninstallDate,
            extraInfo: i.extraInfo,
          };
        } else if (i.action === ActionInventory.delete) {
          data.inventory[key] = {
            action: i.action,
            id: i.id,
            type: i.type,
            quantity: i.quantity,
            model: i.model,
            serialNumber: i.serialNumber,
            installDate: i.installDate,
            uninstallDate: i.uninstallDate,
            extraInfo: i.extraInfo,
          };
        }
      }
    }

    return data;
  }
}
