import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { CommentThreadOutDTO } from "../models/comment/comment-thread.out.dto";
import { CommentOutDTO } from "../models/comment/comment.out.dto";
import { CommentPaginatedOutDTO } from "../models/comment/comment.paginated.out.dto";
import { CommentVisibility } from "../models/comment/type/comment-visibility.enum";

export class CommentService {
  constructor(private methods: HttpMethod) {}

  async create(
    visibility: CommentVisibility,
    category: string,
    content: string,
    objectId?: string,
    anchor?: string,
    notifiedUser?: string[]
  ) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.post<CommentOutDTO>(`/comment`, {
      visibility,
      category,
      content,
      objectId,
      anchor,
      notifiedUser,
    });
    return plainToInstance(CommentOutDTO, result);
  }

  async createMePublic(
    category: string,
    content: string,
    objectId?: string,
    anchor?: string
  ) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.post<CommentOutDTO>(`/comment/me`, {
      visibility: CommentVisibility.Public,
      category,
      content,
      objectId,
      anchor,
    });
    return plainToInstance(CommentOutDTO, result);
  }

  @memoize(10000, "CommentService")
  async list(offset?: number, limit?: number, category?: string) {
    const result = await this.methods.get<CommentPaginatedOutDTO>(`/comment`, {
      params: {
        offset,
        limit,
        category,
      },
    });
    return plainToInstance(CommentPaginatedOutDTO, result);
  }

  @memoize(10000, "CommentService")
  async listMine(offset?: number, limit?: number, category?: string) {
    const result = await this.methods.get<CommentPaginatedOutDTO>(
      `/comment/me`,
      {
        params: {
          offset,
          limit,
          category,
        },
      }
    );
    return plainToInstance(CommentPaginatedOutDTO, result);
  }

  @memoize(10000, "CommentService")
  async getThread(objectId: string) {
    const result = await this.methods.get<CommentThreadOutDTO>(
      `/comment/objectId`,
      {
        params: {
          ids: [objectId],
        },
      }
    );
    return plainToInstance(CommentThreadOutDTO, result);
  }

  @memoize(10000, "CommentService")
  async getMineThread(objectId: string) {
    const result = await this.methods.get<CommentThreadOutDTO>(
      `/comment/me/objectId`,
      {
        params: {
          ids: [objectId],
        },
      }
    );
    return plainToInstance(CommentThreadOutDTO, result);
  }

  async reply(
    commentId: string,
    visibility: CommentVisibility,
    content: string,
    notifiedUser?: string[]
  ) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.post<CommentOutDTO>(
      `/comment/${commentId}`,
      {
        visibility,
        content,
        notifiedUser,
      }
    );
    return plainToInstance(CommentOutDTO, result);
  }

  async replyMePublic(commentId: string, content: string) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.post<CommentOutDTO>(
      `/comment/me/${commentId}`,
      {
        visibility: CommentVisibility.Public,
        content,
      }
    );
    return plainToInstance(CommentOutDTO, result);
  }

  async updateMine(
    commentId: string,
    visibility?: CommentVisibility,
    content?: string,
    anchor?: string
  ) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.patch<CommentOutDTO>(
      `/comment/me/${commentId}`,
      {
        visibility,
        content,
        anchor,
      }
    );
    return plainToInstance(CommentOutDTO, result);
  }

  async update(commentId: string, visibility?: CommentVisibility) {
    clearBucketMemoization("CommentService");
    const result = await this.methods.patch<CommentOutDTO>(
      `/comment/${commentId}`,
      {
        visibility,
      }
    );
    return plainToInstance(CommentOutDTO, result);
  }

  delete(commentId: string) {
    clearBucketMemoization("CommentService");
    return this.methods.delete(`/comment/${commentId}`);
  }

  deleteMine(commentId: string) {
    clearBucketMemoization("CommentService");
    return this.methods.delete(`/comment/me/${commentId}`);
  }
}
