
import Vue from "vue";
import Component from "vue-class-component";
import { StoredNotification } from "@/notification/notification-store";
import { StoreGetter } from "@/store/storeGetter.decorator";

@Component
export default class Notifications extends Vue {
  @StoreGetter("notification/notifications")
  notifications!: StoredNotification[];
  notificationDismissed(notification: StoredNotification) {
    notification.outdated = true;
    this.$notification.clean();
  }
}
