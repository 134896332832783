
import Component from "vue-class-component";
import Vue from "vue";
import CommentThread from "./CommentThread.vue";
import CommentVisibilitySelector from "./CommentVisibilitySelector.vue";
import { Emit, Prop } from "vue-property-decorator";
import { CommentOutDTO } from "@/api/models/comment/comment.out.dto";
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import { StoreGetter } from "@/store/storeGetter.decorator";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import { ACLRight } from "@/api/acl-right.enum";

@Component({
  components: {
    CommentThread,
    CommentVisibilitySelector,
  },
})
export default class CommentCard extends Vue {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;

  @Prop({ type: String, required: false })
  public readonly title!: string;

  @Prop({ type: Array })
  public readonly threads!: CommentOutDTO[][] | null;

  @Prop({ type: String, default: "" })
  public readonly replyContent!: string;

  @Prop({ type: String })
  public readonly replyVisibility!: CommentVisibility;

  @Prop({ type: String })
  public readonly newCommentContent!: string;

  @Prop({ type: String })
  public readonly newCommentVisibility!: CommentVisibility;

  @Prop({ type: Number })
  public readonly replyToThreadIndex!: number;

  @Prop({ type: Number, default: 5 })
  public readonly nbPerPage!: number;

  @Prop({ type: Boolean, default: true })
  public readonly canUpdateVisibility!: boolean;

  @Emit("enableReplyTo")
  public enableReplyTo(
    index: number,
    commentConfig: { commentId: string; commentVisibility: CommentVisibility }
  ) {
    return {
      index,
      ...commentConfig,
    };
  }

  @Emit("deleteThread")
  public deleteThread(commentId: string) {
    return commentId;
  }

  @Emit("deleteMineThread")
  public deleteMineThread(commentId: string) {
    return commentId;
  }

  @Emit("updateComment")
  public updateComment(comment: CommentOutDTO) {
    return comment;
  }

  @Emit("updateMineComment")
  public updateMineComment(comment: CommentOutDTO) {
    return comment;
  }

  @Emit("reply")
  public reply() {
    return;
  }

  @Emit("createComment")
  public createComment() {
    return;
  }

  @Emit("update:replyContent")
  public updateReplyContent(replyContent: string) {
    return replyContent;
  }

  @Emit("update:replyVisibility")
  public updateReplyVisibility(replyVisibility: CommentVisibility) {
    return replyVisibility;
  }

  @Emit("update:newCommentContent")
  public updateNewCommentContent(newCommentContent: string) {
    return newCommentContent;
  }

  @Emit("update:newCommentVisibility")
  public updateNewCommentVisibility(newCommentVisibility: CommentVisibility) {
    return newCommentVisibility;
  }

  @Emit("update:replyToThreadIndex")
  public updateReplyToThreadIndex(replyToThreadIndex: number) {
    return replyToThreadIndex;
  }

  public ACLRight = ACLRight;
  public page = 1;

  public get sortedPaginatedThreads() {
    if (!this.threads) {
      return [];
    }
    const startIndex = (this.page - 1) * this.nbPerPage;
    const endIndex = Math.min(startIndex + this.nbPerPage, this.threads.length);

    return this.threads
      .sort((a, b) => b[0].createdAt.getTime() - a[0].createdAt.getTime())
      .slice(startIndex, endIndex);
  }
}
