import { Type } from "class-transformer";
import { TicketStatus } from "../call-center/type/ticket-status.enum";
import { ClientSummaryListOutDTO } from "../client/client-summary-list.out.dto";
import { UserSummaryOutDTO } from "../user/user-summary.out.dto";
import { UserOutDTO } from "../user/user.out";
import { InterventionDataDTO } from "./intervention-data.dto";
import { InterventionStatus } from "./type/intervention-status.enum";

export class InterventionOutDTO {
  id!: string;
  ref!: number;
  version!: number;

  @Type(() => Date)
  changedAt!: Date;

  @Type(() => UserSummaryOutDTO)
  lastChangeBy!: UserSummaryOutDTO;

  @Type(() => ClientSummaryListOutDTO)
  client!: ClientSummaryListOutDTO;

  type!: string;

  @Type(() => UserOutDTO)
  assignedUsers?: UserOutDTO[];

  assignedCategories?: string[];
  description?: string | null;
  data?: InterventionDataDTO | null;

  status!: TicketStatus;
  priority!: number;

  @Type(() => InterventionOutDTO)
  history?: InterventionOutDTO[];

  get colorFromInterventionStatus() {
    if (this.data?.interventionStatus) {
      switch (this.data?.interventionStatus) {
        case InterventionStatus.InstallationRequested:
          return "purple accent-3 white--text";
        case InterventionStatus.InstallationToDo:
          return "green accent-3";
        case InterventionStatus.InstallationInProgress:
          return "blue white--text";
        case InterventionStatus.AddKiosk:
          return "yellow";
        case InterventionStatus.SwappToDo:
          return "amber";
        case InterventionStatus.ToUninstall:
          return "blue-grey white--text";
        case InterventionStatus.ShopClosed:
          return "black white--text";
        case InterventionStatus.Support:
          return "red darken-1 white--text";
        case InterventionStatus.InstallationCompleted:
          return "lime darken-4 white--text";
        default:
          return "";
      }
    }
  }
}
