import { plainToInstance } from "class-transformer";
import { memoize } from "../../utils/memoize";
import { HttpMethod } from "../http-method.interface";
import { TagsPaginatedOutDTO } from "../models/tag/tags-paginated.out.dto";

export class TagService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "TagService")
  async list(offset?: number, limit?: number, term?: string) {
    const result = await this.methods.get<TagsPaginatedOutDTO>(`/tag`, {
      params: {
        offset,
        limit,
        term,
      },
    });
    return plainToInstance(TagsPaginatedOutDTO, result);
  }
}
