import { ClientStatus } from "../type/client-status.enum";

export function getClientStatusColor(status: ClientStatus) {
  switch (status) {
    case ClientStatus.Active:
      return "success";
    case ClientStatus.Rescinded:
      return "error";
    case ClientStatus.Pending:
      return "info";
    default:
      return "";
  }
}
