export const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const readFileToB64 = (file: File) =>
  new Promise((resolve, reject) => {
    readFile(file)
      .then((data: unknown) => resolve(btoa(data as string)))
      .catch((err) => reject(err));
  });
