import { Type } from "class-transformer";

export class GreyhoundsOutDTO {
  @Type(() => Date)
  date!: Date;

  stereoluxId!: number;
  days!: number;
  creditIn!: number;
  creditOut!: number;
  totalBet!: number;
  totalWin!: number;
}
