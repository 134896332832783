export enum ACLRight {
  ACL_LIST = "acl:list",
  ACL_ADD_DESCRIPTION = "acl:add-description",

  CALL_CENTER_CREATE_TICKET = "call-center:create-ticket",
  CALL_CENTER_DELETE_TICKET = "call-center:delete-ticket",
  CALL_CENTER_EXPORT = "call-center:export",
  CALL_CENTER_GET_STATISTICS = "call-center:get-statistics",
  CALL_CENTER_GET_TICKET = "call-center:get-ticket",
  CALL_CENTER_GET_TICKET_FILE = "call-center:get-ticket-file",
  CALL_CENTER_LIST = "call-center:list",
  CALL_CENTER_LIST_CLIENT_TICKET = "call-center:list-client-ticket",
  CALL_CENTER_LIST_MY_TICKET = "call-center:list-my-ticket",
  CALL_CENTER_SEARCH = "call-center:search",
  CALL_CENTER_SEARCH_MY_TICKET = "call-center:search-my-ticket",
  CALL_CENTER_STORE_TICKET_FILE = "call-center:store-ticket-file",
  CALL_CENTER_UPDATE_TICKET = "call-center:update-ticket",

  CLIENT_ADD_DOCUMENT = "client:add-document",
  CLIENT_ADMIN_UPDATE = "client:admin-update",
  CLIENT_CREATE = "client:create",
  CLIENT_CREATE_CONTRACT = "client:create-contract",
  CLIENT_DELETE_DOCUMENT = "client:delete-document",
  CLIENT_EXPORT = "client:export",
  CLIENT_EXPORT_FINANCIAL = "client:export-financial",
  CLIENT_GET = "client:get",
  CLIENT_GET_CLIENT_FINANCIAL = "client:get-client-financial",
  CLIENT_GET_DOCUMENT = "client:get-document",
  CLIENT_GET_MY_DOCUMENT = "client:get-my-document",
  CLIENT_LIST = "client:list",
  CLIENT_PRE_FILLED_CONTRACT = "client:pre-filled-contract",
  CLIENT_PRE_FILLED_CONTRACT_WITHOUT_CLIENT = "client:pre-filled-contract-without-client",
  CLIENT_PREVIEW_CONTRACT = "client:preview-contract",
  CLIENT_PREVIEW_CONTRACT_WITHOUT_CLIENT = "client:preview-contract-without-client",
  CLIENT_SEARCH = "client:search",
  CLIENT_UPDATE = "client:update",
  CLIENT_UPDATE_CONTRACT = "client:update-contract",
  CLIENT_UPDATE_DOCUMENT = "client:update-document",

  CLIENT_GET_HISTORY = "client-history:get-client-history",

  COMMENT_CREATE = "comment:create",
  COMMENT_DELETE = "comment:delete",
  COMMENT_GET = "comment:get",
  COMMENT_REPLY = "comment:reply",
  COMMENT_UPDATE_VISIBILITY = "comment:update-visibility",

  CONTRACT_TEMPLATE_ADD_ASSET = "contract-template:add-asset",
  CONTRACT_TEMPLATE_ARCHIVE = "contract-template:archive",
  CONTRACT_TEMPLATE_CREATE = "contract-template:create",
  CONTRACT_TEMPLATE_DELETE_ASSET = "contract-template:delete-asset",
  CONTRACT_TEMPLATE_GET = "contract-template:get",
  CONTRACT_TEMPLATE_GET_ASSET = "contract-template:get-asset",
  CONTRACT_TEMPLATE_LIST = "contract-template:list",
  CONTRACT_TEMPLATE_LIST_ASSETS = "contract-template:list-assets",
  CONTRACT_TEMPLATE_UPDATE = "contract-template:update",

  DOCUMENT_CHECK_FILES_COHERENCE = "document:check-files-coherence",

  DOCUSIGN_CREATE_ENVELOPE = "docusign:create-envelope",
  DOCUSIGN_VIEW_URL = "docusign:get-envelope-view-url",
  DOCUSIGN_HAS_CONSENT = "docusign:has-consent",

  ENTERPRISE_BULD_CREATE = "enterprise:bulk-create",
  ENTERPRISE_CREATE = "enterprise:create",
  ENTERPRISE_DELETE = "enterprise:delete",
  ENTERPRISE_DELETE_PERSON_LINK = "enterprise:delete-person-link",
  ENTERPRISE_EXPORT = "enterprise:export",
  ENTERPRISE_DETAILS = "enterprise:get-detail",
  ENTERPRISE_LINK_PERSON = "enterprise:link-person",
  ENTERPRISE_LIST = "enterprise:list",
  ENTERPRISE_SEARCH = "enterprise:search",
  ENTERPRISE_UPDATE = "enterprise:update",
  ENTERPRISE_ADMIN_UPDATE = "enterprise:admin-update",
  ENTERPRISE_UPDATE_PERSON_LINK = "enterprise:update-person-link",

  FINANCIAL_IMPORT = "financial:import",

  GAMBLING_COMMITTEE_ADMIN_LIST = "gambling-committee-admin:list",
  GAMBLING_COMMITTEE_ADMIN_EXPORT = "gambling-committee-admin:export",
  GAMBLING_COMMITTEE_CREATE = "gambling-committee:create",
  GAMBLING_COMMITTEE_UPDATE = "gambling-committee:update",

  GLOBAL_STORAGE_DELETE = "global-storage:delete",
  GLOBAL_STORAGE_GET = "global-storage:get",
  GLOBAL_STORAGE_SET = "global-storage:set",

  INSTALLATION_ADMIN_LIST_INTERVENTION = "installation-admin:list-intervention",
  INSTALLATION_ADMIN_LIST_MY_INTERVENTION = "installation-admin:list-my-intervention",
  INSTALLATION_ADMIN_SEARCH_INTERVENTION = "installation-admin:search-intervention",
  INSTALLATION_ADMIN_SEARCH_MY_INTERVENTION = "installation-admin:search-my-intervention",
  INSTALLATION_CLOSE_INTERVENTION = "installation:close-intervention",
  INSTALLATION_CREATE_INTERVENTION = "installation:create-intervention",
  INSTALLATION_CREATE_INVENTORY = "installation:create-inventory",
  INSTALLATION_DELETE_INTERVENTION = "installation:delete-intervention",
  INSTALLATION_DELETE_INVENTORY = "installation:delete-inventory",
  INSTALLATION_GENERATE_INTERVENTION_DELIVERY_RECEIPT = "installation:generate-intervention-delivery-receipt",
  INSTALLATION_GET = "installation:get",
  INSTALLATION_GET_INTERVENTION = "installation:get-intervention",
  INSTALLATION_GET_MY_INTERVENTION = "installation:get-my-intervention",
  INSTALLATION_GET_INTERVENTION_FILE = "installation:get-intervention-file",
  INSTALLATION_GET_INTERVENTION_FROM_CALL_CENTER_TICKET_ID = "installation:get-intervention-from-call-center-ticket-id",
  INSTALLATION_STORE_INTERVENTION_FILE = "installation:store-intervention-file",
  INSTALLATION_UPDATE_INTERVENTION = "installation:update-intervention",
  INSTALLATION_UPDATE_MY_INTERVENTION = "installation:update-my-intervention",
  INSTALLATION_UPDATE_INVENTORY = "installation:update-inventory",

  MAIL_SEND = "mail:send-mail",
  MAIL_HISTORY_LIST = "mail-history:list",

  PERSON_CREATE = "person:create",
  PERSON_DELETE = "person:delete",
  PERSON_EXPORT = "person:export",
  PERSON_DETAILS = "person:get-detail",
  PERSON_LIST = "person:list",
  PERSON_SEARCH = "person:search",
  PERSON_UPDATE = "person:update",

  PLAYERS_1000 = "players1000:export",

  ROLE_DELETE = "role:delete",
  ROLE_LIST = "role:list",
  ROLE_PUT = "role:put",

  EXTERNAL_FINANCIAL_DATA_FETCH_SFK = "external-financial-data:fetch-sfk",
  EXTERNAL_FINANCIAL_DATA_FETCH_VINCENNES = "external-financial-data:fetch-vincennes",
  EXTERNAL_FINANCIAL_DATA_FETCH_GREYHOUNDS = "external-financial-data:fetch-greyhounds",
  EXTERNAL_FINANCIAL_DATA_GET_DAILY_STATISTICS = "external-financial-data:get-daily-statistics",
  EXTERNAL_FINANCIAL_DATA_GET_SFK_ERRORED = "external-financial-data:get-sfk-errored",
  EXTERNAL_FINANCIAL_DATA_GET_MONTHLY_STATISTICS = "external-financial-data:get-monthly-statistics",
  EXTERNAL_FINANCIAL_DATA_GET_STATISTICS_BY_CLIENT = "external-financial-data:get-statistics-by-client",
  EXTERNAL_FINANCIAL_DATA_GET_STATISTICS_FOR_ALL_CLIENT = "external-financial-data:get-statistics-for-all-client",

  SHOP_BULK_CREATE = "shop:bulk-create",
  SHOP_CREATE = "shop:create",
  SHOP_DELETE = "shop:delete",
  SHOP_DELETE_LINK = "shop:delete-link",
  SHOP_DELETE_PERSON_LINK = "shop:delete-person-link",
  SHOP_EXPORT = "shop:export",
  SHOP_DETAILS = "shop:get-detail",
  SHOP_LINK_PERSON = "shop:link-person",
  SHOP_LIST = "shop:list",
  SHOP_SEARCH = "shop:search",
  SHOP_UPDATE = "shop:update",
  SHOP_UPDATE_PERSON_LINK = "shop:update-person-link",

  SMS_SEARCH = "sms:search",
  SMS_SEND_SMS = "sms:send-sms",

  TAG_CREATE = "tag:create",
  TAG_DELETE = "tag:delete",
  TAG_SEARCH = "tag:search",

  USER_CREATE = "user:create",
  USER_DELETE = "user:delete",
  USER_DETAILS = "user:get-detail",
  USER_LIST = "user:list",
  USER_LIST_INTERNAL = "user:list-internal-user",
  USER_SET_ACTIVATION = "user:set-activation",
  USER_SET_CATEGORY = "user:set-category",
  USER_SET_DISPLAYED_NAME = "user:set-displayed-name",
  USER_SET_LANGUAGE = "user:set-language",
  USER_SET_RIGHT = "user:set-right",
}
