import { Type } from "class-transformer";
import { UserSummaryOutDTO } from "../user/user-summary.out.dto";
import { InventoryModel } from "./type/inventory-model.enum";
import { InventoryType } from "./type/inventory-type.enum";

export class InventoryHistoryOutDTO {
  inventoryId!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => UserSummaryOutDTO)
  lastChangeBy!: UserSummaryOutDTO;

  type!: InventoryType;

  quantity!: number;

  model?: InventoryModel;
  serialNumber?: string;

  @Type(() => Date)
  installDate?: Date;

  @Type(() => Date)
  uninstallDate?: Date;

  extraInfo?: string;
}
