export interface AddendumDefinition {
  name: string;
  type: "boolean";
}

/**
 * Contract / Default
 */
export enum ContractStatus {
  Draft = "draft",
  SentToPartner = "sentToPartner",
  SignedByPartner = "signedByPartner",
  // @TODO : ??Keep
  Rescinded = "rescinded",
  ForcedRescind = "forcedRescind",
}

/**
 * Mayor Opinion
 */
export enum MayorOpinionStatus {
  Draft = "draft",
  SentToMayor = "sentToMayor",
  ReceivedFromMayor = "receivedFromMayor",
}

export interface MayorOpinionSentToMayorStatusAddendum {
  sentByPartner: boolean | undefined;
  sentByBetFirst: boolean | undefined;
}

export const MayorOpinionSentToMayorStatusAddendumDef: AddendumDefinition[] = [
  { name: "sentByPartner", type: "boolean" },
  { name: "sentByBetFirst", type: "boolean" },
];

/**
 * Mayor Agreement
 */
export enum MayorAgreementStatus {
  Draft = "draft",
  SentToMunicipality = "sentToMunicipality",
  ReceivedFromMunicipality = "receivedFromMunicipality",
  AcceptedByMunicipality = "acceptedByMunicipality",
  SentToMayor = "sentToMayor",
  AcceptedByMayor = "acceptedByMayor",
  RefusedByMayor = "refusedByMayor",
}

/**
 * Press Certificate
 * Regional Tax Certificate
 * Federal Certificate
 * Partner Mandate
 */
export enum PartnerExchangeStatus {
  Draft = "draft",
  SentToPartner = "sentToPartner",
  ReceivedFromPartner = "receivedFromPartner",
}

/**
 * Payment Plan
 */
export enum PaymentPlanStatus {
  Draft = "draft",
  SentToPartner = "sentToPartner",
  SignedByPartner = "signedByPartner",
}

export interface PaymentPlanStatusSentToPartnerAddendum {
  sentByMail: boolean | undefined;
  sentByPost: boolean | undefined;
}

export const PaymentPlanStatusSentToPartnerAddendumDef: AddendumDefinition[] = [
  { name: "sentByMail", type: "boolean" },
  { name: "sentByPost", type: "boolean" },
];

/**
 * Formal Notice Non Payment
 */
export enum FormalNoticeNonPaymentStatus {
  Draft = "draft",
  SentToPartner = "sentToPartner",
  PaidByPartner = "paidByPartner",
  SentToLawyer = "sentToLawyer",
}

export interface FormalNoticeNonPaymentStatusSentToPartnerAddendum {
  byMail: boolean | undefined;
  byPost: boolean | undefined;
}

export const FormalNoticeNonPaymentStatusSentToPartnerAddendumDef: AddendumDefinition[] =
  [
    { name: "byMail", type: "boolean" },
    { name: "byPost", type: "boolean" },
  ];

export interface FormalNoticeNonPaymentStatusPaidByPartnerAddendum {
  fullyPaid: boolean | undefined;
  partiallyPaid: boolean | undefined;
}

export const FormalNoticeNonPaymentStatusPaidByPartnerAddendumDef: AddendumDefinition[] =
  [
    { name: "fullyPaid", type: "boolean" },
    { name: "partiallyPaid", type: "boolean" },
  ];
