
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ConfirmationPrompt extends Vue {
  @Prop({ type: String, required: true })
  public title!: string;

  @Prop({ type: String, required: true })
  public confirmLabel!: string;

  @Prop({ type: Boolean, default: false })
  public loading!: boolean;
}
