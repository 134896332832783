import { Type } from "class-transformer";

export class DocumentOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  mimeType?: string;
  extension!: string;
  public!: boolean;
  clientId!: string;
  originalFileName!: string;
  category?: string;
  tags?: string[];

  @Type(() => Date)
  validFrom?: Date;

  @Type(() => Date)
  validUntil?: Date;

  base64Preview!: string;
}
