import { TicketStatus } from "./type/ticket-status.enum";

export const ColorFromStatus = {
  [TicketStatus.Open]: "info",
  [TicketStatus.Waiting]: "warning",
  [TicketStatus.Closed]: "success",
};

export const ColorFromPriority = {
  1: "orange lighten-2",
  2: "red lighten-1",
};
