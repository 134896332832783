import { Type } from "class-transformer";
import { InventoryModel } from "./type/inventory-model.enum";
import { InventoryType } from "./type/inventory-type.enum";

export class InventoryOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  type!: InventoryType;

  quantity!: number;

  model?: InventoryModel;

  serialNumber!: string;

  @Type(() => Date)
  installDate?: Date;

  @Type(() => Date)
  uninstallDate?: Date;

  extraInfo?: string;
}
