import { Expose, Type } from "class-transformer";
import { PersonOutDTO } from "./person.out.dto";

export class PersonPaginatedOutDTO {
  @Expose()
  @Type(() => PersonOutDTO)
  persons!: PersonOutDTO[];

  @Expose()
  total!: number;
}
