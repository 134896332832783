import { Lang } from "@/utils/lang.enum";
import { Type } from "class-transformer";
import { ContractTemplateSummaryOutDTO } from "./contract-template-summary.out.dto";
import { ContractTemplateTarget } from "./contract-template-type.enum";
import { ContractType } from "./contract-type.enum";
import { CustomFields } from "./custom-fields.type";
import { TemplateOutDTO } from "./template.out.dto";
import { CommissionBasedOn } from "../client/type/commission-based-on.enum";

export class ContractTemplateOutDTO implements ContractTemplateSummaryOutDTO {
  name!: string;
  target!: ContractTemplateTarget;
  contractType!: ContractType;
  commissionType!: CommissionBasedOn | null;

  @Type(() => Date)
  createdAt!: Date;

  lang!: Lang;
  version!: number;
  customFields!: CustomFields;
  archived!: boolean;

  @Type(() => TemplateOutDTO)
  template!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  header!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  footer!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  versoHeader!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  versoFooter!: TemplateOutDTO;

  font?: string;
  margin?: string;
  fontSize?: string;
}
