export enum ErrorCode {
  UNKNOWN = "UNKNOWN",
  VALIDATION_FAILED = "VALIDATION_FAILED",
  UNAUTHORIZED = "UNAUTHORIZED",
  THROTTLING = "THROTTLING",
  BANNED = "BANNED",

  AUTH_ACCESS_TOKEN_INVALID = "AUTH_ACCESS_TOKEN_INVALID",
  AUTH_REFRESH_TOKEN_INVALID = "AUTH_REFRESH_TOKEN_INVALID",

  ACL_NOT_FOUND = "ACL_NOT_FOUND",
  ACL_RIGHT_NOT_FOUND = "ACL_RIGHT_NOT_FOUND",

  USER_WRONG_LOGIN_OR_PASSWORD = "USER_WRONG_LOGIN_OR_PASSWORD",
  USER_DISABLED = "USER_DISABLED",
  USER_UNCONFIRMED = "USER_UNCONFIRMED",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  USER_ALREADY_EXIST = "USER_ALREADY_EXIST",
  USER_RESET_PASSWORD_TOKEN_INVALID = "USER_RESET_PASSWORD_TOKEN_INVALID",
  USER_RESET_PASSWORD_TOKEN_EXPIRED = "USER_RESET_PASSWORD_TOKEN_EXPIRED",

  INSTALLATION_INTERVENTION_DELIVERY_RECEIPT_ALREADY_GENERATED = "INSTALLATION_INTERVENTION_DELIVERY_RECEIPT_ALREADY_GENERATED",

  CALL_CENTER_INVALID_TICKET_FILES = "CALL_CENTER_INVALID_TICKET_FILES",
}
