import { Type } from "class-transformer";
import { ClientType } from "../client/type/client-type.enum";
import { FinancialOutDTO } from "../financial/financial.out.dto";
import { ExternalFinancialDataStatisticsOutDTO } from "./external-financial-data-statistics.out.dto";
import { ExternalFinancialDataType } from "./external-financial-data-type.enum";
import { GreyhoundsOutDTO } from "./greyhounds.out.dto";

export class ExternalFinancialOutDTO {
  type!: ExternalFinancialDataType;

  @Type(() => ExternalFinancialDataStatisticsOutDTO)
  data!: ExternalFinancialDataStatisticsOutDTO;
}

export class ClientStatisticOutDTO {
  clientId!: string | null;
  clientType!: ClientType | null;
  shopId!: string | null;
  shopRef!: number | null;
  shopPointOfSaleName!: string | null;
  pointOfSaleNamesFallback!: string;

  @Type(() => ExternalFinancialOutDTO)
  externalFinancial!: ExternalFinancialOutDTO[];

  @Type(() => GreyhoundsOutDTO)
  greyhounds?: GreyhoundsOutDTO;

  @Type(() => FinancialOutDTO)
  financial?: FinancialOutDTO;
}

export class StatisticsByClientOutDTO {
  @Type(() => ClientStatisticOutDTO)
  statistics!: ClientStatisticOutDTO[];
}
