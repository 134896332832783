import { Type } from "class-transformer";
import { ExternalFinancialDataStatisticsOutDTO } from "./external-financial-data-statistics.out.dto";
import { ExternalFinancialDataType } from "./external-financial-data-type.enum";

export class ExternalFinancialDataMonthlyStatisticsOutDTO extends ExternalFinancialDataStatisticsOutDTO {
  type!: ExternalFinancialDataType;

  @Type(() => Date)
  date!: Date;

  countBettingShop!: number;
  countNewsAgent!: number;
}
