
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import LangMenu from "@/components/LangMenu.vue";
import ThemeSwitch from "@/components/ThemeSwitch.vue";
import SideMenu from "@/components/SideMenu.vue";
import { StoreGetter } from "@/store/storeGetter.decorator";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    SideMenu,
    LangMenu,
    ThemeSwitch,
  },
})
export default class DefaultLayout extends Vue {
  @StoreGetter("user/user") user!: UserWithRightOutDTO;
  @StoreGetter("user/darkMode") storedDarkMode!: boolean;

  public showDrawer = true;
  public darkMode = false;
  public showScrollToTop = false;

  mounted() {
    this.darkMode = this.storedDarkMode;
  }

  public onUpdateDark(value: boolean) {
    this.$store.commit("user/setDarkMode", value);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onScroll(e: any) {
    if (e && e.srcElement?.scrollingElement?.scrollTop) {
      const topScroll = e.srcElement.scrollingElement.scrollTop;
      this.showScrollToTop = topScroll > 10;
    }
  }

  public scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
