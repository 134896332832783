import { HttpMethod } from "../http-method.interface";
import { LoginOutDTO } from "../models/login.out.interface";
import { RefreshOutDTO } from "../models/refresh.out.interface";

export interface UserAuthCallbackToken {
  setAccessToken: (token: string | undefined) => void;
  setRefreshToken: (token: string | undefined) => void;
  getRefreshToken: () => string | undefined;
}

export class UserAuthService {
  constructor(
    private methods: HttpMethod,
    private callback?: UserAuthCallbackToken
  ) {}

  async login(email: string, password: string) {
    const result = await this.methods.post<LoginOutDTO>("/user/auth/login", {
      email,
      password,
    });
    if (this.callback) {
      this.callback.setAccessToken(result.accessToken);
      this.callback.setRefreshToken(result.refreshToken);
    }
  }

  async refresh(refreshToken?: string) {
    const result = await this.methods.post<RefreshOutDTO>(
      "/user/auth/refresh",
      {
        refreshToken: refreshToken ?? this.callback?.getRefreshToken(),
      }
    );
    if (this.callback) {
      this.callback.setAccessToken(result.accessToken);
    }
  }

  async logout() {
    await this.methods.post<void>("/user/auth/logout");
    if (this.callback) {
      this.callback.setAccessToken(undefined);
      this.callback.setRefreshToken(undefined);
    }
  }
}
