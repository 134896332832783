
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import SMSActivator from "@/components/sms/SMSActivator.vue";
import { ReceiverItem } from "@/api/models/sms/type/receiver-item.interface";

@Component({
  components: {
    SMSActivator,
  },
})
export default class GlobalSMSActivator extends Vue {
  @Prop({ type: Boolean, default: false })
  public readonly fab!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly noDialog!: boolean;

  @Prop({ type: String, default: "primary" })
  public readonly color!: string;

  @Prop({ type: String, default: "en" })
  public readonly lang!: string;

  @Prop({ type: Array, required: false })
  public readonly receiverItems?: ReceiverItem[];

  public sendSMSFunction = (receivers: string[], message: string) =>
    this.$api.SMS.send(receivers, message);
}
