import { render, staticRenderFns } from "./ThemeSwitch.vue?vue&type=template&id=1ca62cb1&scoped=true&"
import script from "./ThemeSwitch.vue?vue&type=script&lang=ts&"
export * from "./ThemeSwitch.vue?vue&type=script&lang=ts&"
import style0 from "./ThemeSwitch.vue?vue&type=style&index=0&id=1ca62cb1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca62cb1",
  null
  
)

export default component.exports