import { Expose, Type } from "class-transformer";
import { InterventionSummaryListOutDTO } from "./intervention-summary-list.out.dto";

export class InterventionPaginatedOutDTO {
  @Type(() => InterventionSummaryListOutDTO)
  interventions!: InterventionSummaryListOutDTO[];

  @Expose()
  total!: number;
}
