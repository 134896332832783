import { Type } from "class-transformer";
import { ShopSummaryOutDTO } from "../shop/shop-summary.out.dto";
import { JuridicalSituation } from "../shop/type/juridical-situation.enum";
import { EnterpriseType } from "./enterprise-type.enum";
import { LegalPersonJuridicalForm } from "./legal-person-juridical-form.enum";
import { PersonInEnterpriseOutDTO } from "./person-in-enterprise-out.dto";

export class EnterpriseOutDTO {
  cbeNumber!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  type!: EnterpriseType;
  name!: string;
  juridicalForm!: LegalPersonJuridicalForm;
  juridicalSituation?: JuridicalSituation;

  address1?: string;
  address2?: string;
  postalCode?: number;
  city?: string;
  houseNumber?: string;

  province!: string;
  region!: string;

  bankAccountNumber?: string;

  @Type(() => ShopSummaryOutDTO)
  shops?: ShopSummaryOutDTO[];

  @Type(() => PersonInEnterpriseOutDTO)
  persons?: PersonInEnterpriseOutDTO[];
}
