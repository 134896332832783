import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1A1A1A",
        secondary: "#333333",
        accent: "#FAE000",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#d14200",
      },
      dark: {
        primary: "#F7DF52",
        secondary: "#333333",
        accent: "#F7DF52",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
