
import Notifications from "@/components/Notifications.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    Notifications,
  },
})
export default class App extends Vue {
  get layout(): string {
    return this.$route.meta?.layout ?? "DefaultLayout";
  }

  async logout() {
    try {
      await this.$api.auth.logout();
    } catch (err) {
      // nothing to do in case of error
      // cleaning will happen anyway
    }
    this.$store.commit("user/setUser", undefined);
    this.$store.commit("user/setSelectedClient", undefined);
    this.$store.commit("user/clearRefreshToken");
    this.$store.commit("user/setDarkMode", false);
    this.$store.commit("user/clearSelectedFilters");
    this.$store.commit("user/clearDarkMode");
    this.$store.commit("shopMap/clear");
    this.$router.replace({ name: "Login" });
  }
}
