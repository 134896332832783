import { Type } from "class-transformer";
import { ClientSummaryListOutDTO } from "../client/client-summary-list.out.dto";
import { UserSummaryOutDTO } from "../user/user-summary.out.dto";
import { UserOutDTO } from "../user/user.out";
import { ColorFromStatus } from "./call-center-ticket-color";
import { CallCenterTicketData } from "./call-center-ticket-data";
import { TicketStatus } from "./type/ticket-status.enum";

export class CallCenterTicketOutDTO {
  id!: string;
  ref!: number;
  version!: number;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => UserSummaryOutDTO)
  createdBy!: UserSummaryOutDTO;

  @Type(() => Date)
  changedAt!: Date;

  @Type(() => UserSummaryOutDTO)
  lastChangeBy!: UserSummaryOutDTO;

  @Type(() => ClientSummaryListOutDTO)
  client?: ClientSummaryListOutDTO;

  type!: string;

  @Type(() => UserOutDTO)
  assignedUsers?: UserOutDTO[];

  assignedCategories?: string[];
  description?: string | null;
  data?: CallCenterTicketData | null;
  status!: TicketStatus;
  priority!: number;

  @Type(() => CallCenterTicketOutDTO)
  history?: CallCenterTicketOutDTO[];

  public get color() {
    return ColorFromStatus[this.status];
  }
}
