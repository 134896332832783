type CacheMap = Map<string | symbol, { exp: number; value: unknown }>;
const defaultBucketSymbol = Symbol("default");
const cacheMapList: Map<string | symbol, CacheMap[]> = new Map();

export function clearAllMemoization() {
  for (const [, mapArray] of cacheMapList) {
    for (const map of mapArray) {
      map.clear();
    }
  }
}

export function clearBucketMemoization(
  bucket: string | symbol = defaultBucketSymbol
) {
  const mapArray = cacheMapList.get(bucket) ?? [];
  for (const map of mapArray) {
    map.clear();
  }
}

export function memoize(
  timeToLiveInMs: number,
  bucket: string | symbol = defaultBucketSymbol
) {
  return function (
    _target: unknown,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const savedValue: CacheMap = new Map();
    const list = cacheMapList.get(bucket) ?? [];
    list.push(savedValue);
    cacheMapList.set(bucket, list);

    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: unknown[]) {
      const key = JSON.stringify(args);
      const cached = savedValue.get(key);
      const now = Date.now();
      if (cached !== undefined && cached.exp > now) {
        return cached.value;
      } else {
        const value = await originalMethod.apply(this, args);
        savedValue.set(key, { exp: now + timeToLiveInMs, value });
        setTimeout(() => {
          for (const [key, value] of savedValue.entries()) {
            if (value.exp <= now) {
              savedValue.delete(key);
            }
          }
        });
        return value;
      }
    };
  };
}
