import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { TemplateDTO } from "../models/contract/template.dto";
import { MailHistoryQueryFilterDTO } from "../models/mail/mail-history-filter.dto";
import { MailHistoryPaginatedOutDTO } from "../models/mail/mail-history-paginated.out.dto";
import { MailHistoryQueryOrderDTO } from "../models/mail/mail-history-query-order.dto";

export class MailService {
  constructor(private methods: HttpMethod) {}

  send(
    emails: string[],
    message: TemplateDTO,
    subject: string,
    files?: File[]
  ) {
    clearBucketMemoization("MailService");
    return this.methods.postFile("/mail/send", {
      emails,
      message: JSON.stringify(message),
      subject,
      files,
    });
  }

  @memoize(10000, "MailService")
  async search(
    offset?: number,
    limit?: number,
    order?: MailHistoryQueryOrderDTO,
    filter?: MailHistoryQueryFilterDTO,
    search?: string
  ) {
    const params = plainToInstance(MailHistoryPaginatedOutDTO, {
      offset,
      limit,
      order: order ? btoa(JSON.stringify(order)) : undefined,
      filter: filter ? btoa(JSON.stringify(filter)) : undefined,
      search,
    });
    const result = await this.methods.get<MailHistoryPaginatedOutDTO>(
      `/mail-history`,
      {
        params,
      }
    );
    return plainToInstance(MailHistoryPaginatedOutDTO, result);
  }
}
