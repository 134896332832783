import { Type } from "class-transformer";
import { TicketTextI18NOutDTO } from "./ticket-text-i18n.out.dto";

export class TicketFieldSelectOutDTO {
  @Type(() => TicketTextI18NOutDTO)
  label!: TicketTextI18NOutDTO;

  value!: string;

  @Type(() => TicketFieldSelectOutDTO)
  subChoices?: TicketFieldSelectOutDTO[];
}
