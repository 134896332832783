import { Type } from "class-transformer";
import { ShopSummaryOutDTO } from "../shop/shop-summary.out.dto";
import { ClientSituation } from "./type/client-situation.enum";
import { ClientStatus } from "./type/client-status.enum";
import { ClientType } from "./type/client-type.enum";
import { EnterpriseSummaryOutDTO } from "../enterprise/enterprise-summary.out.dto";
import { getClientStatusColor } from "./utils/client-status-color";

export class ClientSummaryOutDTO {
  id!: string;
  shopId!: string;
  enterpriseId!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  status!: ClientStatus;
  situation!: ClientSituation[];
  distributor?: string;
  type!: ClientType;
  vincennesId?: string;

  @Type(() => ShopSummaryOutDTO)
  shop?: ShopSummaryOutDTO;

  @Type(() => EnterpriseSummaryOutDTO)
  enterprise?: EnterpriseSummaryOutDTO;

  public get statusColor() {
    return getClientStatusColor(this.status);
  }
}
