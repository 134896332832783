import { Type } from "class-transformer";
import { ClientSummaryOutDTO } from "../client/client-summary.out.dto";
import { CoordinatesOutDTO } from "../coordinates.out.dto";

export class ShopCoordinatesOutDTO {
  id!: string;
  pointOfSaleName?: string;

  @Type(() => ClientSummaryOutDTO)
  clients?: ClientSummaryOutDTO[];

  @Type(() => CoordinatesOutDTO)
  coordinates?: CoordinatesOutDTO;
}
