
import { CommentVisibility } from "@/api/models/comment/type/comment-visibility.enum";
import { mapEnumToItem } from "@/utils/map-enum-to-items.function";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import CommentVisibilityIcon from "./CommentVisibilityIcon.vue";

@Component({
  components: {
    CommentVisibilityIcon,
  },
})
export default class CommentVisibilitySelector extends Vue {
  @Prop({ type: String, default: CommentVisibility.Public })
  public readonly value!: CommentVisibility;

  @Emit("input")
  public emitInput(visibility: CommentVisibility) {
    return visibility;
  }

  @Watch("value", { immediate: true })
  valueChanged(value: CommentVisibility) {
    this.lazyVisibility = value;
  }

  public CommentVisibility = CommentVisibility;
  public showMenu = false;
  public lazyVisibility: CommentVisibility = CommentVisibility.Public;
  public readonly commentVisibilityItems = mapEnumToItem(
    CommentVisibility,
    "comment.visibility"
  );
}
