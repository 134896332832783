
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

@Component
export default class ThemeSwitch extends Vue {
  @Prop({ type: Boolean, required: true })
  public readonly dark!: boolean;

  @Emit("update:dark")
  public emitUpdateDark(dark: boolean) {
    return dark;
  }

  @Watch("dark", { immediate: true })
  public onDarkChange(dark: boolean) {
    this.switchMode(dark);
  }

  public switchMode(value: boolean | null) {
    this.$vuetify.theme.dark = value ?? false;
    this.emitUpdateDark(value ?? false);
  }
}
