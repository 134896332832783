import { HttpMethod } from "../http-method.interface";
import { PersonFieldsDataWithRole } from "../models/person/form/person-fields-data-with-role";
import { PersonService } from "../services/person.service";

export class PersonUtilService {
  constructor(private methods: HttpMethod) {}

  async createPersons(personsToCreate: PersonFieldsDataWithRole[]) {
    const personService = new PersonService(this.methods);

    const createdPersons: { role: string | undefined; personId: string }[] = [];
    const uniquePersons: Record<string, string> = {};

    for (const personToCreate of personsToCreate) {
      const key = `${personToCreate.person.firstName}${personToCreate.person.lastName}`;
      if (uniquePersons[key] !== undefined) {
        createdPersons.push({
          role: personToCreate.role,
          personId: uniquePersons[key],
        });
      } else {
        const personCreated = await personService.create(
          personToCreate.person.firstName || undefined,
          personToCreate.person.lastName || undefined,
          personToCreate.person.address1 || undefined,
          personToCreate.person.address2 || undefined,
          personToCreate.person.postalCode
            ? Number.parseInt(personToCreate.person.postalCode)
            : undefined,
          personToCreate.person.city || undefined,
          personToCreate.person.houseNumber || undefined,
          personToCreate.person.country || undefined,
          personToCreate.person.bankAccountNumber || undefined,
          personToCreate.person.phones || undefined,
          personToCreate.person.emails || undefined,
          personToCreate.person.stateRegisterNumber || undefined,
          personToCreate.person.nationality || undefined,
          personToCreate.person.birthDate || undefined,
          personToCreate.person.birthLocation || undefined
        );

        createdPersons.push({
          role: personToCreate.role,
          personId: personCreated.id,
        });

        uniquePersons[key] = personCreated.id;
      }
    }

    return createdPersons;
  }
}
