import { Lang } from "@/utils/lang.enum";
import { plainToInstance } from "class-transformer";
import { clearBucketMemoization, memoize } from "../../utils/memoize";
import { HttpMethod } from "../http-method.interface";
import { PersonSearchPaginatedDTO } from "../models/person/person-search-paginated.dto";
import { PersonOutDTO } from "../models/person/person.out.dto";
import { PersonPaginatedOutDTO } from "../models/person/persons-paginated.out.dto";

export class PersonService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "PersonService")
  async getDetail(id: string) {
    const result = await this.methods.get<PersonOutDTO>(`/person/${id}`);
    return plainToInstance(PersonOutDTO, result);
  }

  @memoize(10000, "PersonService")
  async list(offset?: number, limit?: number) {
    const result = await this.methods.get<PersonPaginatedOutDTO>(`/person`, {
      params: {
        offset,
        limit,
      },
    });
    return plainToInstance(PersonPaginatedOutDTO, result);
  }

  @memoize(10000, "PersonService")
  async search(term: string, offset?: number, limit?: number) {
    const params = plainToInstance(PersonSearchPaginatedDTO, {
      offset,
      limit,
      term,
    });
    const result = await this.methods.get<PersonPaginatedOutDTO>(
      `/person/search`,
      {
        params,
      }
    );
    return plainToInstance(PersonPaginatedOutDTO, result);
  }

  async create(
    firstName: string | undefined,
    lastName: string | undefined,
    address1: string | undefined,
    address2: string | undefined,
    postalCode: number | undefined,
    city: string | undefined,
    houseNumber: string | undefined,
    country: string | undefined,
    bankAccountNumber: string | undefined,
    phones: string[] | undefined,
    emails: string[] | undefined,
    stateRegisterNumber: string | undefined,
    nationality: string | undefined,
    birthDate: string | undefined,
    birthLocation: string | undefined
  ) {
    clearBucketMemoization("PersonService");
    const result = await this.methods.post<PersonOutDTO>(`/person`, {
      firstName,
      lastName,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      country,
      bankAccountNumber,
      phones,
      emails,
      stateRegisterNumber,
      nationality,
      birthDate,
      birthLocation,
    });
    return plainToInstance(PersonOutDTO, result);
  }

  async update(
    id: string,
    firstName: string | undefined,
    lastName: string | undefined,
    address1: string | undefined,
    address2: string | undefined,
    postalCode: number | undefined,
    city: string | undefined,
    houseNumber: string | undefined,
    country: string | undefined,
    bankAccountNumber: string | undefined,
    phones: string[] | undefined,
    emails: string[] | undefined,
    stateRegisterNumber: string | undefined,
    nationality: string | undefined,
    birthDate: string | undefined,
    birthLocation: string | undefined
  ) {
    clearBucketMemoization("PersonService");
    clearBucketMemoization("ShopService");
    const result = await this.methods.patch<PersonOutDTO>(`/person/${id}`, {
      firstName,
      lastName,
      address1,
      address2,
      postalCode,
      city,
      houseNumber,
      country,
      bankAccountNumber,
      phones,
      emails,
      stateRegisterNumber,
      nationality,
      birthDate,
      birthLocation,
    });
    return plainToInstance(PersonOutDTO, result);
  }

  delete(id: string) {
    clearBucketMemoization("PersonService");
    return this.methods.delete(`/person/${id}`);
  }

  async export(
    term?: string,
    includeOnly?: string[],
    exclude?: string[],
    lang?: Lang
  ) {
    return await this.methods.getRaw(`/person/export`, {
      params: {
        term: term ? term : undefined,
        includeOnly,
        exclude,
        lang,
      },
    });
  }
}
