import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { FinancialLogImportOutDTO } from "../models/financial/financial-log-import.out.dto";

export class FinancialService {
  constructor(private methods: HttpMethod) {}

  async import(file: File) {
    await this.methods.postFile(`/financial/import`, {
      file,
    });
    return;
  }

  async getLastImportLog() {
    const result = await this.methods.get(`/financial/last-import-log`);
    return plainToInstance(FinancialLogImportOutDTO, result);
  }
}
