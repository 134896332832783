import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
    meta: {
      layout: "AuthenticateLayout",
      noAuthorizationRequired: true,
    },
  },
  {
    path: "/ask-reset-password",
    name: "AskResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/auth/AskResetPassword.vue"
      ),
    meta: {
      layout: "AuthenticateLayout",
      noAuthorizationRequired: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/ResetPassword.vue"),
    meta: {
      layout: "AuthenticateLayout",
      noAuthorizationRequired: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Home.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/user/Profile.vue"),
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/user/Users.vue"),
  },
  {
    path: "/admin/roles",
    name: "Roles",
    component: () =>
      import(/* webpackChunkName: "roles" */ "../views/acl/Roles.vue"),
  },
  {
    path: "/shop/new",
    name: "ShopCreate",
    component: () =>
      import(/* webpackChunkName: "shopCreate" */ "../views/shop/ShopEdit.vue"),
  },
  {
    path: "/map/shops",
    name: "ShopsMap",
    component: () =>
      import(/* webpackChunkName: "shopsMap" */ "../views/shop/ShopsMap.vue"),
  },
  {
    path: "/shop/:id/edit",
    name: "ShopEdit",
    component: () =>
      import(/* webpackChunkName: "shopEdit" */ "../views/shop/ShopEdit.vue"),
  },
  {
    path: "/shop/:id",
    name: "Shop",
    component: () =>
      import(
        /* webpackChunkName: "shopDetail" */ "../views/shop/ShopDetail.vue"
      ),
  },
  {
    path: "/shops",
    name: "Shops",
    component: () =>
      import(/* webpackChunkName: "shop" */ "../views/shop/Shops.vue"),
  },
  {
    path: "/enterprises",
    name: "Enterprises",
    component: () =>
      import(
        /* webpackChunkName: "enterprise" */ "../views/enterprise/Enterprises.vue"
      ),
  },
  {
    path: "/enterprise/new",
    name: "EnterpriseCreate",
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseCreate" */ "../views/enterprise/EnterpriseEdit.vue"
      ),
  },
  {
    path: "/enterprise/:cbeNumber/edit",
    name: "EnterpriseEdit",
    component: () =>
      import(
        /* webpackChunkName: "enterpriseEdit" */ "../views/enterprise/EnterpriseEdit.vue"
      ),
  },
  {
    path: "/enterprise/:cbeNumber",
    name: "Enterprise",
    component: () =>
      import(
        /* webpackChunkName: "enterpriseDetail" */ "../views/enterprise/EnterpriseDetail.vue"
      ),
  },
  {
    path: "/persons",
    name: "Persons",
    component: () =>
      import(/* webpackChunkName: "person" */ "../views/person/Persons.vue"),
  },
  {
    path: "/person/new",
    name: "PersonCreate",
    component: () =>
      import(
        /* webpackChunkName: "personCreate" */ "../views/person/PersonEdit.vue"
      ),
  },
  {
    path: "/person/:id/edit",
    name: "PersonEdit",
    component: () =>
      import(
        /* webpackChunkName: "personEdit" */ "../views/person/PersonEdit.vue"
      ),
  },
  {
    path: "/person/:id",
    name: "Person",
    component: () =>
      import(
        /* webpackChunkName: "personDetail" */ "../views/person/PersonDetail.vue"
      ),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () =>
      import(/* webpackChunkName: "client" */ "../views/client/Clients.vue"),
  },
  {
    path: "/client/new",
    name: "ClientCreate",
    component: () =>
      import(
        /* webpackChunkName: "clientCreate" */ "../views/client/ClientCreate.vue"
      ),
  },
  {
    path: "/client/:id/edit",
    name: "ClientEdit",
    component: () =>
      import(
        /* webpackChunkName: "clientEdit" */ "../views/client/ClientEdit.vue"
      ),
  },
  {
    path: "/client/:id",
    name: "Client",
    component: () =>
      import(
        /* webpackChunkName: "clientDetail" */ "../views/client/ClientDetail.vue"
      ),
    children: [
      {
        path: "document",
        name: "ClientDocument",
        component: () =>
          import(
            /* webpackChunkName: "clientDocument" */ "../views/client/tabs/ClientDocumentTab.vue"
          ),
      },
      {
        path: "gambling-committee",
        name: "ClientGamblingCommittee",
        component: () =>
          import(
            /* webpackChunkName: "clientGamblingCommittee" */ "../views/client/tabs/ClientGamblingCommitteeTab.vue"
          ),
      },
      {
        path: "info",
        name: "ClientInfo",
        component: () =>
          import(
            /* webpackChunkName: "clientInfo" */ "../views/client/tabs/ClientInfoTab.vue"
          ),
      },
      {
        path: "financial",
        name: "ClientFinancial",
        component: () =>
          import(
            /* webpackChunkName: "clientFinancial" */ "../views/client/tabs/ClientFinancialTab.vue"
          ),
      },
      {
        path: "installation",
        name: "ClientInstallation",
        component: () =>
          import(
            /* webpackChunkName: "clientInstallation" */ "../views/client/tabs/ClientInstallationTab.vue"
          ),
      },
      {
        path: "call-center",
        name: "ClientCallCenter",
        component: () =>
          import(
            /* webpackChunkName: "clientCallCenter" */ "../views/client/tabs/ClientCallCenterTab.vue"
          ),
      },
      {
        path: "history",
        name: "ClientHistory",
        component: () =>
          import(
            /* webpackChunkName: "clientHistory" */ "../views/client/tabs/ClientHistoryTab.vue"
          ),
      },
    ],
  },
  {
    path: "/client/:id/document/:contractId/generate",
    name: "ClientContractGenerate",
    component: () =>
      import(
        /* webpackChunkName: "clientContractGenerate" */ "../views/client/contract/ContractGenerate.vue"
      ),
  },
  {
    path: "/client/:id/document/generate",
    name: "ClientContractGenerateNew",
    component: () =>
      import(
        /* webpackChunkName: "ClientContractGenerateNew" */ "../views/client/contract/ContractGenerate.vue"
      ),
  },
  {
    path: "/client/:id/intervention/:interventionId/edit",
    name: "InterventionEdit",
    component: () =>
      import(
        /* webpackChunkName: "interventionEdit" */ "../views/intervention/InterventionEdit.vue"
      ),
  },
  {
    path: "/client/:id/intervention/:interventionId",
    name: "Intervention",
    component: () =>
      import(
        /* webpackChunkName: "interventionDetail" */ "../views/intervention/InterventionDetail.vue"
      ),
  },
  {
    path: "/call-center/tickets",
    name: "CallCenterTickets",
    component: () =>
      import(
        /* webpackChunkName: "callCenterTickets" */ "../views/call-center/CallCenterTickets.vue"
      ),
  },
  {
    path: "/call-center/ticket/new",
    name: "CallCenterTicketCreate",
    component: () =>
      import(
        /* webpackChunkName: "callCenterTicketCreate" */ "../views/call-center/CallCenterTicketCreate.vue"
      ),
  },
  {
    path: "/call-center/ticket/:ticketId",
    name: "CallCenterTicket",
    component: () =>
      import(
        /* webpackChunkName: "callCenterTicketDetail" */ "../views/call-center/CallCenterTicketDetail.vue"
      ),
  },
  {
    path: "/interventions",
    name: "Interventions",
    component: () =>
      import(
        /* webpackChunkName: "intervention" */ "../views/intervention/Interventions.vue"
      ),
  },
  {
    path: "/intervention/new",
    name: "InterventionCreate",
    component: () =>
      import(
        /* webpackChunkName: "interventionCreate" */ "../views/intervention/InterventionEdit.vue"
      ),
  },
  {
    path: "/admin/templates",
    name: "Templates",
    component: () =>
      import(
        /* webpackChunkName: "template" */ "../views/document/template/Templates.vue"
      ),
  },
  {
    path: "/admin/templates/new",
    name: "TemplateCreate",
    component: () =>
      import(
        /* webpackChunkName: "templateCreate" */ "../views/document/template/TemplateEdit.vue"
      ),
  },
  {
    path: "/admin/template/:name/:lang/edit",
    name: "TemplateEdit",
    component: () =>
      import(
        /* webpackChunkName: "templateEdit" */ "../views/document/template/TemplateEdit.vue"
      ),
  },
  {
    path: "/admin/settings",
    name: "Settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/Settings.vue"
      ),
  },
  {
    path: "/admin/statistics",
    name: "Statistics",
    component: () =>
      import(
        /* webpackChunkName: "statistics" */ "../views/statistics/Statistics.vue"
      ),
    children: [
      {
        path: "daily",
        name: "DailyStatistics",
        component: () =>
          import(
            /* webpackChunkName: "dailyStatistics" */ "../views/statistics/DailyStatistics.vue"
          ),
      },
      {
        path: "monthly",
        name: "MonthlyStatistics",
        component: () =>
          import(
            /* webpackChunkName: "monthlyStatistics" */ "../views/statistics/MonthlyStatistics.vue"
          ),
      },
      {
        path: "by-client",
        name: "ByClientStatistics",
        component: () =>
          import(
            /* webpackChunkName: "byClientStatistics" */ "../views/statistics/ByClientStatistics.vue"
          ),
      },
    ],
  },
  {
    path: "/admin/statistics/payment",
    name: "AgencyManagerPayment",
    component: () =>
      import(
        /* webpackChunkName: "agencyManagerPayment" */ "../views/statistics/AgencyManagerPayment.vue"
      ),
  },
  {
    path: "/admin/players1000",
    name: "Players1000",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/players1000/Players1000.vue"
      ),
  },
  {
    path: "/me/tickets",
    name: "MyCallCenterTicket",
    component: () =>
      import(
        /* webpackChunkName: "myCallCenterTicket" */ "../views/me/MyCallCenterTickets.vue"
      ),
  },
  {
    path: "/me/ticket/new",
    name: "MyCallCenterTicketCreate",
    component: () =>
      import(
        /* webpackChunkName: "myCallCenterTicketCreate" */ "../views/call-center/CallCenterTicketCreate.vue"
      ),
  },
  {
    path: "/me/shop",
    name: "MyShop",
    component: () =>
      import(/* webpaclChunkName: "myShop" */ "../views/me/shop/MyShop.vue"),
    children: [
      {
        path: "info",
        name: "MyShopInfo",
        component: () =>
          import(
            /* webpackChunkName: "myShopInfo" */ "../views/me/shop/tabs/MyShopInfoTab.vue"
          ),
      },
      {
        path: "document",
        name: "MyShopDocument",
        component: () =>
          import(
            /* webpackChunkName: "myShopDocument" */ "../views/me/shop/tabs/MyShopDocumentTab.vue"
          ),
      },
      {
        path: "financial",
        name: "MyShopFinancial",
        component: () =>
          import(
            /* webpackChunkName: "myShopFinancial" */ "../views/me/shop/tabs/MyShopFinancialTab.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
