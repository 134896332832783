export enum LicenceStatus {
  Accepted = "accepted",
  Cancelled = "cancelled",
  Draft = "draft",
  Expired = "expired",
  Refused = "refused",
  SignedByClient = "signedByClient",
  WaitingForClientSignature = "waitingForClientSignature",
  WaitingForCommissionFeedback = "waitingForCommissionFeedback",
}
