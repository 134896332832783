import { RightOutDTO } from "../right.out";
import { UserOutDTO } from "./user.out";

export class UserWithRightOutDTO extends UserOutDTO {
  rights!: RightOutDTO[];

  hasRight(rightName?: string | string[]) {
    if (rightName === undefined) {
      return true;
    }
    if (Array.isArray(rightName)) {
      return rightName.every((right) => {
        return this.rights.find((r) => r.name === right) !== undefined;
      });
    } else {
      return (
        this.rights.find((right) => right.name === rightName) !== undefined
      );
    }
  }
}
