import { Type } from "class-transformer";
import { ActionInventory } from "./type/action-inventory.enum";
import { InterventionStatus } from "./type/intervention-status.enum";
import { ActionInventoryCreationDTO } from "./action-inventory-creation.dto";
import { ActionInventoryDeleteDTO } from "./action-inventory-delete.dto";
import { ActionInventoryUpdateDTO } from "./action-inventory-update.dto";
import { InterventionFileUploadOutDTO } from "./intervention-file-upload.out.dto";

export class InterventionDataDTO {
  interventionStatus!: InterventionStatus;
  changesApplied?: boolean;
  callCenterTicketId?: string;

  @Type(() => Date)
  installationDate?: Date;

  inventoryConflictIds?: string[];

  @Type(() => Object, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: "action",
      subTypes: [
        { value: ActionInventoryCreationDTO, name: ActionInventory.creation },
        { value: ActionInventoryUpdateDTO, name: ActionInventory.update },
        { value: ActionInventoryDeleteDTO, name: ActionInventory.delete },
      ],
    },
  })
  inventory?: (
    | ActionInventoryCreationDTO
    | ActionInventoryUpdateDTO
    | ActionInventoryDeleteDTO
  )[];

  tourNumber?: number;

  @Type(() => InterventionFileUploadOutDTO)
  files?: InterventionFileUploadOutDTO[];

  @Type(() => InterventionFileUploadOutDTO)
  deliveryReceipt?: InterventionFileUploadOutDTO;
}
