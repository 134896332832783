import { Type } from "class-transformer";
import { ShopOutDTO } from "../shop/shop.out.dto";
import { GamblingCommitteeOutDTO } from "./gambling-committee.out.dto";
import { ClientSituation } from "./type/client-situation.enum";
import { ClientStatus } from "./type/client-status.enum";
import { ClientType } from "./type/client-type.enum";
import { EnterpriseOutDTO } from "../enterprise/enterprise.out.dto";
import { getClientStatusColor } from "./utils/client-status-color";
import { ClientPeriodicity } from "./type/client-periodicity.enum";

export class ClientSummaryListOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  status!: ClientStatus;
  situation!: ClientSituation[];
  distributor?: string;
  financialAmount?: number;
  financialLines?: number;
  brand?: string;
  sfkContractNumber?: string;
  contractDurationInMonth!: number;
  exclusivity!: boolean;
  periodicity!: ClientPeriodicity;
  feesAndTaxesRatio!: number;
  weekNegativeReport!: number;
  type!: ClientType;

  @Type(() => GamblingCommitteeOutDTO)
  activeGamblingCommittees?: GamblingCommitteeOutDTO;

  @Type(() => GamblingCommitteeOutDTO)
  lastGamblingCommittee?: GamblingCommitteeOutDTO;

  @Type(() => GamblingCommitteeOutDTO)
  gamblingCommittee?: GamblingCommitteeOutDTO[];

  @Type(() => ShopOutDTO)
  shop!: ShopOutDTO;

  @Type(() => EnterpriseOutDTO)
  enterprise!: EnterpriseOutDTO;

  public get statusColor() {
    return getClientStatusColor(this.status);
  }
}
