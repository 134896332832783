import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { ConsentOutDTO } from "../models/docusign/consent.out.dto";
import { EnvelopeStatus } from "../models/docusign/envelope-status.enum";
import { EnvelopeViewOutDTO } from "../models/docusign/envelope-view.out.dto";
import { EnvelopeOutDTO } from "../models/docusign/envelope.out.dto";

export class DocusignService {
  constructor(private methods: HttpMethod) {}

  async createEnvelope(
    clientId: string,
    status: EnvelopeStatus,
    documentIds?: string[],
    carbonCopiesPersonIds?: string[],
    carbonCopiesUserEmails?: string[]
  ) {
    return plainToInstance(
      EnvelopeOutDTO,
      await this.methods.post<EnvelopeOutDTO>(
        `/docusign/${clientId}/envelope`,
        {
          status,
          documentIds,
          carbonCopiesPersonIds,
          carbonCopiesUserEmails,
        }
      )
    );
  }

  async getConsent(callbackPath?: string) {
    return plainToInstance(
      ConsentOutDTO,
      await this.methods.get<ConsentOutDTO>("/docusign/consent", {
        params: { callbackPath },
      })
    );
  }

  async viewEnvelopeUrl(clientId: string, envelopeId: string) {
    return plainToInstance(
      EnvelopeViewOutDTO,
      this.methods.get(`/docusign/${clientId}/envelope/${envelopeId}/viewUrl`)
    );
  }

  async listEnvelope(clientId: string) {
    return plainToInstance(
      EnvelopeOutDTO,
      await this.methods.get<EnvelopeOutDTO>(`/docusign/${clientId}/envelope`)
    );
  }
}
