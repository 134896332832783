
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LanguageIcon extends Vue {
  @Prop({ type: String, required: true })
  public lang!: "fr" | "nl" | "en";
}
