import { Type } from "class-transformer";
import { UserSummaryOutDTO } from "../user/user-summary.out.dto";
import { CommentVisibility } from "./type/comment-visibility.enum";

export class CommentOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  visibility!: CommentVisibility;
  thread!: string;
  category!: string;
  objectId?: string;
  anchor?: string;
  content!: string;

  @Type(() => UserSummaryOutDTO)
  creator!: UserSummaryOutDTO;

  @Type(() => UserSummaryOutDTO)
  notifiedUser?: UserSummaryOutDTO[];
}
