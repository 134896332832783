import { Type } from "class-transformer";
import { DocumentSummaryOutDTO } from "../document/document-summary.out.dto";
import { EnvelopeStatus } from "./envelope-status.enum";

export class EnvelopeOutDTO {
  id!: string;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  status!: EnvelopeStatus;

  @Type(() => DocumentSummaryOutDTO)
  documents!: DocumentSummaryOutDTO[];
}
