import { Type } from "class-transformer";

export class BlockOutDTO {
  id!: string;
  type!: string;
  data?: Record<string, unknown>;
}

export class TemplateOutDTO {
  time!: number;

  @Type(() => BlockOutDTO)
  blocks!: BlockOutDTO[];

  version!: string;
}

export class TemplatePartsOutDTO {
  @Type(() => TemplateOutDTO)
  body!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  header!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  footer!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  versoHeader!: TemplateOutDTO;

  @Type(() => TemplateOutDTO)
  versoFooter!: TemplateOutDTO;
}
