import { Type } from "class-transformer";
import { InventoryModel } from "./type/inventory-model.enum";

export class InventoryUpdateDTO {
  quantity?: number;
  model?: InventoryModel | null;
  serialNumber?: string;

  @Type(() => Date)
  installDate?: Date | null;

  @Type(() => Date)
  uninstallDate?: Date | null;

  extraInfo?: string | null;
}
