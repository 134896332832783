import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { HttpMethod } from "../http-method.interface";

export class StorageService {
  constructor(private methods: HttpMethod) {}

  @memoize(60000, "StorageService")
  async listStorage() {
    return await this.methods.get<Record<string, unknown>>("/storage");
  }

  @memoize(60000, "StorageService")
  async getStorage(key: string) {
    return await this.methods.get<unknown>(`/storage/${key}`);
  }

  async setStorage(key: string, data: unknown) {
    clearBucketMemoization("StorageService");
    return await this.methods.put<unknown>(`/storage/${key}`, data);
  }

  async removeStorage(key: string) {
    clearBucketMemoization("StorageService");
    return await this.methods.delete(`/storage/${key}`);
  }
}
