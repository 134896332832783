import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { InstallationOutDTO } from "../models/installation/installation.out.dto";
import { InventoryHistoryOutDTO } from "../models/installation/inventory-history.out.dto";
import { InventoryOutDTO } from "../models/installation/inventory.out.dto";
import { InventoryModel } from "../models/installation/type/inventory-model.enum";
import { InventoryType } from "../models/installation/type/inventory-type.enum";

export class InstallationService {
  constructor(private methods: HttpMethod) {}

  @memoize(10000, "InstallationService")
  async getDetail(clientId: string) {
    const result = await this.methods.get<InstallationOutDTO>(
      `/client/${clientId}/installation`
    );
    return plainToInstance(InstallationOutDTO, result);
  }

  async createInventory(
    clientId: string,
    type: InventoryType,
    quantity: number,
    serialNumber?: string,
    model?: InventoryModel,
    installDate?: Date,
    uninstallDate?: Date,
    extraInfo?: string
  ) {
    clearBucketMemoization("InstallationService");
    const result = await this.methods.post<InventoryOutDTO>(
      `/client/${clientId}/installation/inventory`,
      {
        type,
        installDate,
        quantity,
        model,
        serialNumber,
        uninstallDate,
        extraInfo,
      }
    );
    return plainToInstance(InventoryOutDTO, result);
  }

  async updateInventory(
    clientId: string,
    inventoryId: string,
    quantity?: number,
    model?: InventoryModel,
    serialNumber?: string,
    installDate?: Date,
    uninstallDate?: Date,
    extraInfo?: string
  ) {
    clearBucketMemoization("InstallationService");
    const result = await this.methods.patch<InventoryOutDTO>(
      `/client/${clientId}/installation/inventory/${inventoryId}`,
      {
        installDate,
        quantity,
        model,
        serialNumber,
        uninstallDate,
        extraInfo,
      }
    );
    return plainToInstance(InventoryOutDTO, result);
  }

  async deleteInventory(clientId: string, inventoryId: string) {
    clearBucketMemoization("InstallationService");
    await this.methods.delete(
      `/client/${clientId}/installation/inventory/${inventoryId}`
    );
  }

  @memoize(10000, "InstallationService")
  async getInventoryHistory(clientId: string) {
    return plainToInstance(
      InventoryHistoryOutDTO,
      await this.methods.get<InventoryHistoryOutDTO[]>(
        `/client/${clientId}/installation/inventory-history`
      )
    );
  }
}
