import { ClientSummaryOutDTO } from "@/api/models/client/client-summary.out.dto";
import { UserWithRightOutDTO } from "@/api/models/user/user-with-right.out";
import { Lang } from "@/utils/lang.enum";

const state = {
  user: undefined as undefined | UserWithRightOutDTO,
  selectedClient: undefined as undefined | ClientSummaryOutDTO,
  selectedFilters: {} as Record<string, string | null>,
};
export type UserState = typeof state;

enum localStorageKeys {
  REFRESH_TOKEN = "refreshToken",
  REMEMBER_ME = "rememberMe",
  LANGUAGE = "lang",
  DARK_MODE = "darkMode",
  SELECTED_FILTERS = "selectedFilters",
}

export const userStore = {
  namespaced: true,
  state,
  mutations: {
    storeRefreshToken(state: UserState, payload: string) {
      localStorage.setItem(localStorageKeys.REFRESH_TOKEN, payload);
    },
    clearRefreshToken() {
      localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
    },
    setRememberMe(state: UserState, payload: boolean) {
      localStorage.setItem(localStorageKeys.REMEMBER_ME, `${payload}`);
    },
    clearRememberMe() {
      localStorage.removeItem(localStorageKeys.REMEMBER_ME);
    },
    setUser(state: UserState, payload: UserWithRightOutDTO) {
      state.user = payload;
    },
    setLang(state: UserState, payload: Lang) {
      localStorage.setItem(localStorageKeys.LANGUAGE, payload);
    },
    setDarkMode(state: UserState, payload: boolean) {
      localStorage.setItem(localStorageKeys.DARK_MODE, payload ? "1" : "0");
    },
    clearDarkMode() {
      localStorage.removeItem(localStorageKeys.DARK_MODE);
    },
    setSelectedClient(state: UserState, payload: ClientSummaryOutDTO) {
      state.selectedClient = payload;
    },
    setSelectedFilters(state: UserState, payload: [string, string | null]) {
      state.selectedFilters[payload[0]] = payload[1];
      localStorage.setItem(
        localStorageKeys.SELECTED_FILTERS,
        JSON.stringify(state.selectedFilters)
      );
    },
    clearSelectedFilters() {
      localStorage.removeItem(localStorageKeys.SELECTED_FILTERS);
    },
  },
  getters: {
    storedRefreshToken: () => {
      return localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
    },
    rememberMe: () => {
      return localStorage.getItem(localStorageKeys.REMEMBER_ME) === "true";
    },
    user: (state: UserState) => {
      return state.user;
    },
    lang: () => {
      return localStorage.getItem(localStorageKeys.LANGUAGE) ?? Lang.en;
    },
    darkMode: () => {
      return localStorage.getItem(localStorageKeys.DARK_MODE) === "1";
    },
    selectedClient: () => {
      return state.selectedClient;
    },
    selectedFilters: (): Record<string, string | null> => {
      const storedSelectedFilters = localStorage.getItem(
        localStorageKeys.SELECTED_FILTERS
      );
      state.selectedFilters = storedSelectedFilters
        ? JSON.parse(storedSelectedFilters)
        : {};
      return state.selectedFilters;
    },
  },
};
