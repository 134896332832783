import { Type } from "class-transformer";
import { DocumentOutDTO } from "../document/document.out.dto";
import { ContractChangeLogOutDTO } from "./contract-change-log.out.dto";
import { ContractTemplateSummaryOutDTO } from "./contract-template-summary.out.dto";
import { ContractType } from "./contract-type.enum";
import { TemplateOutDTO } from "./template.out.dto";
import { ContractGenerationMethod } from "./type/contract-generation-method.enum";

export class ContractOutDTO {
  id!: string;
  type!: ContractType;
  generationMethod!: ContractGenerationMethod;
  status!: string;
  statusAddendum?: Record<string, unknown>;

  @Type(() => Date)
  createdAt!: Date;

  @Type(() => Date)
  updatedAt!: Date;

  @Type(() => DocumentOutDTO)
  documentHistory!: DocumentOutDTO[];

  @Type(() => DocumentOutDTO)
  lastDocument?: DocumentOutDTO;

  @Type(() => ContractChangeLogOutDTO)
  changeLogs?: ContractChangeLogOutDTO[];

  @Type(() => ContractTemplateSummaryOutDTO)
  template?: ContractTemplateSummaryOutDTO;

  customFieldsValue?: Record<string, unknown>;

  @Type(() => TemplateOutDTO)
  contentBody?: TemplateOutDTO;
}
