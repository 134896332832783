import { clearBucketMemoization, memoize } from "@/utils/memoize";
import { plainToInstance } from "class-transformer";
import { HttpMethod } from "../http-method.interface";
import { ExternalFinancialDataDailyStatisticsOutDTO } from "../models/external-financial-data/external-financial-data-daily-statistics.out.dto";
import { SfkErroredOutDTO } from "../models/external-financial-data/sfk-data-errored.out.dto";
import { ExternalFinancialDataMonthlyStatisticsOutDTO } from "../models/external-financial-data/external-financial-data-monthly-statistics.out.dto";
import { StatisticsByClientOutDTO } from "../models/external-financial-data/statistics-by-client.out.dto";

export class ExternalFinancialDataService {
  constructor(private methods: HttpMethod) {}

  @memoize(43200000, "ExternalFinancialDataService")
  async getDailyStatistics(from?: Date, to?: Date) {
    const result = await this.methods.get<
      ExternalFinancialDataDailyStatisticsOutDTO[]
    >(`/external-financial-data/statistics/daily`, { params: { from, to } });
    return plainToInstance(ExternalFinancialDataDailyStatisticsOutDTO, result);
  }

  @memoize(43200000, "ExternalFinancialDataService")
  async getMonthlyStatistics(from?: Date, to?: Date) {
    const result = await this.methods.get<
      ExternalFinancialDataMonthlyStatisticsOutDTO[]
    >(`/external-financial-data/statistics/monthly`, { params: { from, to } });
    return plainToInstance(
      ExternalFinancialDataMonthlyStatisticsOutDTO,
      result
    );
  }

  @memoize(43200000, "ExternalFinancialDataService")
  async getStatisticsByClient(
    clientId?: string,
    managedClient?: boolean,
    from?: Date,
    to?: Date
  ) {
    const result = await this.methods.get<StatisticsByClientOutDTO>(
      `/external-financial-data/statistics/client/${clientId || ""}`,
      { params: { from, to, managedClient } }
    );
    return plainToInstance(StatisticsByClientOutDTO, result);
  }

  @memoize(43200000, "ExternalFinancialDataService")
  async getSfkDataErrored(date?: Date) {
    const result = await this.methods.get<SfkErroredOutDTO>(
      `/external-financial-data/sfk-data-errored`,
      { params: { date } }
    );
    return plainToInstance(SfkErroredOutDTO, result);
  }

  @memoize(43200000, "ExternalFinancialDataService")
  async getMyClientStatistics(clientId: string) {
    const result = await this.methods.get<StatisticsByClientOutDTO>(
      `/external-financial-data/me/statistics/client/${clientId}`
    );
    return plainToInstance(StatisticsByClientOutDTO, result);
  }

  async fetchSfk(from: string, to: string) {
    clearBucketMemoization("ExternalFinancialDataService");
    await this.methods.get("/external-financial-data/fetch-sfk", {
      params: { from, to },
    });
  }

  async fetchVincennes(from: string, to: string) {
    clearBucketMemoization("ExternalFinancialDataService");
    await this.methods.get("/external-financial-data/fetch-vincennes", {
      params: { from, to },
    });
  }

  async fetchGreyhounds() {
    clearBucketMemoization("ExternalFinancialDataService");
    await this.methods.get("/external-financial-data/fetch-greyhounds");
  }
}
